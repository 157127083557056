import React, { Component } from "react";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Component
import Form from "../../../components/uielements/form";
import Button from "../../../components/uielements/button";
import Icon from "../../../assets/components/Icon";
import Color from "../../../assets/Color";
import SignUpStyleWrapper, { CustomStyles } from "./style";
import * as actions from "../../../redux/actions";
import { validateField, validateForm } from "../../../helpers/validation";
import Modals from "../../../components/feedback/modal";

import { Document, Page } from 'react-pdf';
import ModalPDFView from "./ModalPDFView";
import ModalUploadDocument from "./ModalUploadDocument";

// Config
import Helper from "../../../helpers/helpers";


// UI Component

const FormItem = Form.Item;

class SignupDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        police_license: {
          value: null,
          validation: {
            rules: {
              required: true
            },
            error_message: {
              required: "Please upload Police License."
            }
          },
          valid: true
        },
        local_authority_license: {
          value: null,
          validation: {
            rules: {
              required: true
            },
            error_message: {
              required: "Please upload Local Authority License."
            }
          },
          valid: true
        },
        ssm: {
          value: null,
          validation: {
            rules: {
              required: true
            },
            error_message: {
              required: "Please upload SSM."
            }
          },
          valid: true
        },
        identity_card: {
          value: null,
          validation: {
            rules: {
              required: true
            },
            error_message: {
              required: "Please upload Identity Card."
            }
          },
          valid: true
        },
      },
      error_message: {},
      page: "",
      modalPDFViewVisible: false,
      document:{},
      viewFilePath: "",
      viewFileAction: "",
      screen: "",
      selectedDocType: null,
      uploadedFile: null,
      valueUpdated: false,
      loadingDoc:{},
    };
  }

  componentDidMount() {

    this.props.onLoadGraderDocumentation(); 

  }
  
  
  componentDidUpdate(prevProps, nextProps) {
    
    const { field_errors, isSignupCompleteSuccess, signup_verify_email, isUploadDocumentationSuccess, graderDocuments, isProcessLoadGraderDocumentation } = this.props; 

    let fields = cloneDeep(this.state.fields);
    let loadingDoc = cloneDeep(this.state.loadingDoc);
    let error_message = cloneDeep(this.state.error_message);
    
    if (
      JSON.stringify(prevProps.field_errors) !== JSON.stringify(field_errors)
    ) {

      this._updateStateError(field_errors);
    }  
    
    
    if (
      JSON.stringify(prevProps.isSignupCompleteSuccess) !== JSON.stringify(isSignupCompleteSuccess) && isSignupCompleteSuccess &&      
      JSON.stringify(prevProps.signup_verify_email) !== JSON.stringify(signup_verify_email) && signup_verify_email
    ) {

      this._signUpVerifyEmail();
    }  

    if (
      !isProcessLoadGraderDocumentation &&
      (JSON.stringify(prevProps.graderDocuments) !== JSON.stringify(graderDocuments) && Object.keys(graderDocuments).length > 0)
    ) {

      Object.keys(graderDocuments).map(key => {

        if(!loadingDoc[`${key}${graderDocuments[key].doc_detail.checksum}`])
        {
          loadingDoc[`${key}${graderDocuments[key].doc_detail.checksum}`] = true
          fields[key].value = graderDocuments[key]
          
          let validator = validateField(fields, key, error_message);
          this.setState({ ...validator, loadingDoc, fields });
          
          Helper.loadSecureFile(graderDocuments[key].doc_detail.file, file => {
       
            fields[key].value.file = file      

            this.setState({ fields });
          });
        }

        return true;
      })
    }  
    
    if (
      JSON.stringify(prevProps.isUploadDocumentationSuccess) !== JSON.stringify(isUploadDocumentationSuccess) && isUploadDocumentationSuccess
    ) {

      this.setState({ page: "", valueUpdated: false, selectedDocType: null}, () => {
        
        this.props.onLoadGraderDocumentation();

      });
    }  
    
  }


  _proceedBack = (discard = false) => {

    const { page, valueUpdated } = this.state;
    
    if(page === "upload_doc")
    {
      if(valueUpdated && !discard)
        this.setState({screen: "discard_change_confirm"})

      else
        this.setState({ page: "", screen: "", valueUpdated: false});

    }else{

      if(discard) 
        this.props.onDismissRegister();
      
      else
        this.setState({screen: "discard_change_confirm"})
    }
  }
  
  _onContinue = () => {
    
    this.setState({screen: ""})
    
  };
  
  _signUpVerifyEmail = () => {
          
    const handleOk = () => {

      this.props.onDismissRegister();
    };
      
    Modals.success({
      title: "Registration Successful!",
      content: "Thank you for registering with us. Your account is currently pending approval by the site administrator. As soon as your request is approved you will receive a confirmation email.",
      onOk() {
        handleOk();
      },
      okText: "OK",
    });

  }


  _updateStateError = (errors) => {
    let error_message = cloneDeep(this.state.error_message);
    let fields = cloneDeep(this.state.fields);

    errors.map(e => {

      if(fields[e.param])
      {
        fields[e.param].valid = false
        error_message[e.param] = e.msg
      }
      
      return null;
    })
    
    this.setState({ error_message, fields });
  };



  _onSubmit = async () => {

    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    let validator = await validateForm(fields, error_message);

    this.setState({ ...validator.data }, () => {

      if (!validator.valid) 
        this._handleInvalidForm();

      else
        this.props.onCompleteSignup();
    
    });
  };

  
  _valueUpdated = () => {

    this.setState({ valueUpdated: true });
  };

  _proceedContinue = () => {
    
    this.setState({screen: ""})
  }


  _proceedUploadDoc = (doc_type) => {
    
    this.setState({page: "upload_doc", selectedDocType: doc_type})
  }

  _uploadDocumentation = params => {
    
    this.props.onUploadDocumentation(params);
  }


  _onChangeText = (name, value) => {
    let fields = cloneDeep(this.state.fields);

    if(name === "organization_phoneNo")
    {     
      if(value.length <= 10)
        fields[name].value = value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');

    }else{

      fields[name].value = value;
    }

    this.setState({ fields });
  };

  _onChangeState = (value = "") => {

    let fields = cloneDeep(this.state.fields);
    fields["state"].value = value;
    
    this.setState({ fields }, () => {

      if(value !== "")
        this.props.onLoadRecyclingList({state: value});
    });
  };

  _onInsertVeriCode = (value) => {

    let fields = cloneDeep(this.state.fields);
 
    fields["veri_code"].value = value;
    
    this.setState({ fields }, () => {
      
      if(value.length === 4)
      {      
        let countryCode = fields.countryCode.value;

        let params = {
          contact_no: countryCode + fields.phoneNo.value,
          oper: "login",
          veri_code: value
        };

        this.props.onVerifySMS(params);
      }
    });

  };

  _onHandleBlurr = e => {
    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    let name = "";

    if (typeof e === "string") {
      name = e;
    } else {
      name = e.target.name;
    }

    let validator = validateField(fields, name, error_message);

    this.setState({ ...validator });
  };

  _handleInvalidForm = () => {
    let fields = cloneDeep(this.state.fields);
    Object.keys(fields).some(name => {
      if (!fields[name].valid) {
        return true;
      }
      return false;
    });
  };




  /* ===== Images ===== */
  _uploadImageSelection = () => {

    let userAgent = navigator.userAgent

    if(userAgent.search("iPhone;") > -1)
      this.setState({screen: "selection", onClickSelection: null})

    else
      this._onSelectPhotos()
  }

  _onDismissSelection = () => {

    this.setState({screen: "", onClickSelection: null})
  }

  _onSelectPhotos = () => {
    
    this.setState({onClickSelection: "uploadIC"})
  }

  _onSelectCamera = () => {

    this.setState({onClickSelection: "captureIC"})
  }

  _onResetUploadClick = () => {

    this.setState({onClickSelection: null})
  }
  
  
  /* ===== File ===== */
  _handleFileChange = (e, name) => {
    
    const file = e.target.files;

    if(file && file[0])
      this.setState({ uploadedFile: {file, name} });
    
  };

  _hideModalPDFView = () => {
    this.setState({ modalPDFViewVisible: false,  viewFileAction: "",  viewFilePath: ""});
  };


  _showModalPDFeView = (action) => {
    let fields = cloneDeep(this.state.fields);
    
    let doc = (fields[action].value) ? fields[action].value.file : null
    
    if(doc)
      this.setState({ modalPDFViewVisible: true, viewFileAction: action,  viewFilePath: doc });    
  };
  /* ===== END File ===== */

  
  renderListLoading = (type) => {

    return (
      <div className="list-loading">

        {type === "grid" && (
          <React.Fragment>                
            <div className="main-item">          
                <div className="sub-rect pure-background"></div>
                <div className="sub-rect pure-background"></div>          
            </div>

            <div className="main-item">
                <div className="small-text">
                  <div className="small-sub-rect pure-background"></div>
                </div>
                
                <div className="small-text">
                  <div className="small-sub-rect pure-background"></div>
                </div>
            </div>

            <div className="main-item mt-3">          
                <div className="sub-rect pure-background"></div>
                <div className="sub-rect pure-background"></div>          
            </div>

            <div className="main-item">          
                <div className="small-text">
                  <div className="small-sub-rect pure-background"></div>
                </div>
                <div className="small-text">
                  <div className="small-sub-rect pure-background"></div>
                </div>
            </div>

            <div className="main-item mt-3">          
                <div className="sub-rect pure-background"></div>
                <div className="sub-rect pure-background"></div>          
            </div>
          </React.Fragment>
        )}
        
        {type === "list" && (
          <React.Fragment>                
            
            <div className="list-rect pure-background"></div>  
            <div className="list-rect pure-background mt-3"></div>  
            <div className="list-rect pure-background mt-3"></div>  
            <div className="list-rect pure-background mt-3"></div>
            <div className="list-rect pure-background mt-3"></div>
          </React.Fragment>
        )}
        
        {type === "summary" && (
          <React.Fragment>                
            
            <div className="list-rect pure-background"></div>  
            <div className="list-rect pure-background mt-3"></div>    
            <div className="css-dom mt-5"></div>
            <div className="css-dom mt-4"></div>
          </React.Fragment>
        )}
      </div>
    )
  }

  renderLoader = () => {
    
    return (<div className="list-loading">
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>      
    </div>)
  }

  render() {
    
    const { view, proceedSignupUploadDocument }  = this.props
    const { fields, page, isProcessSignupComplete, screen, selectedDocType, loadingDoc }  = this.state


    return (
      <CustomStyles>
        
        <div className="pg-header">
          {page === "" && (
            
            <div className="text-bold text-center">Doucmentation</div>
          )}

          {page === "upload_doc" && (
            
            <div className="text-bold text-center">Upload Document</div>
          )}

          {(!proceedSignupUploadDocument || page === "upload_doc") && (
            <div 
              className="backBtn"
              onClick={() => this._proceedBack()}
            >
              <Icon
                type="custom"
                name="back"
                color={Color.Black}
                size={15}
              />
            </div>
          )}
        </div>
            
        
              
        {page === "" && (
          <React.Fragment>
            <SignUpStyleWrapper className="isoSignInPage"  id="container-top">
              
              <div className="isoLoginContentWrapper">
                <div className="isoLoginContent">

                    <div className="isoSignInForm">
                      <Form layout="vertical">

                          {/* Police License */}
                          <div className="document-container">
                            <div className="d-flex justify-content-between align-middle">
                              <div className="font-title color-darkgrey" id="police_license">Police License (*.pdf)</div>
                              <div
                                  className="icon-box"            
                                  onClick={() => this._proceedUploadDoc("police_license")}
                                >
                                  <i className="ion-edit" />
                              </div>
                            </div>
          
                            {fields["police_license"].value && (
                              <div className="isoImageCon pt-1"> 

                                {loadingDoc["police_license"] ? (
                                  <div className="list-loading mb-2">
                                    <div className="list-rect pure-background pt-4 ">
                                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    </div>
                                  </div>
                                ): (

                                  <div className="file-wrapper mb-2">
                                    {fields["police_license"].value.file ? (
                                      <div>

                                        <Document
                                          file={fields["police_license"].value.file}
                                          className="document-box"
                                          loading={this.renderLoader()}
                                        >
                                          <Page pageNumber={1}/>
                                        </Document>

                                        <div className="text-center font-bold text-underline">{ (fields["police_license"].value.doc_detail.originalname.length > 32) ? `${fields["police_license"].value.doc_detail.originalname.slice(0, 30)}...` : fields["police_license"].value.doc_detail.originalname}</div>
                                      </div>
                                    ) : (
                                      <div className="font-bold">No File Uploaded.</div>
                                    )}
                                  </div>
                                )}

                                <div><span className="font-bold">License No.: </span>{fields["police_license"].value.license_no}</div>

                                <div><span className="font-bold">Expiry Date: </span>{fields["police_license"].value.valid_until}</div>
                              </div>
                            )}

                            <FormItem
                              validateStatus={
                                !fields["police_license"].valid ? "error" : "validating"
                              }
                              help={this.state.error_message["police_license"]}
                            />     
                          </div>
                          {/* END Police License */} 


                          {/* Local Authority License */} 
                          <div className="document-container mt-3"> 
                            <div className="d-flex justify-content-between align-middle">
                              <div className="font-title color-darkgrey" id="local_authority_license">Local Authority License (*.pdf)</div>
                              <div
                                  className="icon-box"            
                                  onClick={() => this._proceedUploadDoc("local_authority_license")}
                                >
                                  <i className="ion-edit" />
                              </div>
                            </div>

                            {fields["local_authority_license"].value && (
                              <div className="isoImageCon pt-1"> 

                                {loadingDoc["local_authority_license"] ? (
                                  <div className="list-loading mb-2">
                                    <div className="list-rect pure-background pt-4 ">
                                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    </div>
                                  </div>
                                ): (

                                  <div className="file-wrapper mb-2">
                                    {fields["local_authority_license"].value.file ? (
                                      <div>

                                        <Document
                                          file={fields["local_authority_license"].value.file}
                                          className="document-box"
                                          loading={this.renderLoader()}
                                        >
                                          <Page pageNumber={1}/>
                                        </Document>

                                        <div className="text-center font-bold text-underline">{ (fields["local_authority_license"].value.doc_detail.originalname.length > 32) ? `${fields["local_authority_license"].value.doc_detail.originalname.slice(0, 30)}...` : fields["local_authority_license"].value.doc_detail.originalname}</div>
                                      </div>
                                    ) : (
                                      <div className="font-bold">No File Uploaded.</div>
                                    )}
                                  </div>
                                )}

                                <div><span className="font-bold">License No.: </span>{fields["local_authority_license"].value.license_no}</div>

                                <div><span className="font-bold">Expiry Date: </span>{fields["local_authority_license"].value.valid_until}</div>
                              </div>
                            )}

                            <FormItem
                              validateStatus={
                                !fields["local_authority_license"].valid ? "error" : "validating"
                              }
                              help={this.state.error_message["local_authority_license"]}
                            />
                          </div>
                          {/* END Local Authority License */} 

                          {/* SSM */}  
                          <div className="document-container mt-3"> 
                            <div className="d-flex justify-content-between align-middle">
                              <div className="font-title color-darkgrey" id="ssm">SSM (*.pdf)</div>
                              <div
                                  className="icon-box"            
                                  onClick={() => this._proceedUploadDoc("ssm")}
                                >
                                  <i className="ion-edit" />
                              </div>
                            </div>

                            {fields["ssm"].value && (
                              <div className="isoImageCon pt-1"> 

                                {loadingDoc["ssm"] ? (
                                  <div className="list-loading mb-2">
                                    <div className="list-rect pure-background pt-4 ">
                                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    </div>
                                  </div>
                                ): (

                                  <div className="file-wrapper mb-2">
                                    {fields["ssm"].value.file ? (
                                      <div>

                                        <Document
                                          file={fields["ssm"].value.file}
                                          className="document-box"
                                          loading={this.renderLoader()}
                                        >
                                          <Page pageNumber={1}/>
                                        </Document>

                                        <div className="text-center font-bold text-underline">{ (fields["ssm"].value.doc_detail.originalname.length > 32) ? `${fields["ssm"].value.doc_detail.originalname.slice(0, 30)}...` : fields["ssm"].value.doc_detail.originalname}</div>
                                      </div>
                                    ) : (
                                      <div className="font-bold">No File Uploaded.</div>
                                    )}
                                  </div>
                                )}

                                <div><span className="font-bold">Expiry Date: </span>{fields["ssm"].value.valid_until}</div>

                              </div>
                            )}

                            <FormItem
                              validateStatus={
                                !fields["ssm"].valid ? "error" : "validating"
                              }
                              help={this.state.error_message["ssm"]}
                            />
                          </div>
                          {/* END SSM */} 

                          {/* Identity Card */}
                          <div className="document-container mt-3"> 
                            <div className="d-flex justify-content-between align-middle">
                              <div className="font-title color-darkgrey" id="identity_card">Identity Card (*.image)</div>
                              <div
                                  className="icon-box"            
                                  onClick={() => this._proceedUploadDoc("identity_card")}
                                >
                                  <i className="ion-edit" />
                              </div>
                            </div>

                            {fields["identity_card"].value && (
                            
                              <div className="isoImageCon pt-1"> 

                                {loadingDoc["identity_card"] ? (
                                  <div className="list-loading mb-2">
                                    <div className="list-rect pure-background pt-4 ">
                                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    </div>
                                  </div>
                                ): (

                                  <div className="img-wrapper mb-2">
                                    {fields["identity_card"].value.file ? (
                                      <div>
                                        <img alt="" className="document-box" src={fields["identity_card"].value.file} />
                                        
                                        <div className="text-center font-bold text-underline">{ (fields["identity_card"].value.doc_detail.originalname.length > 32) ? `${fields["identity_card"].value.doc_detail.originalname.slice(0, 30)}...` : fields["identity_card"].value.doc_detail.originalname}</div>
                                      </div>
                                    ) : (
                                      <div className="font-bold">No File Uploaded.</div>
                                    )}
                                  </div>
                                )}

                                <div><span className="font-bold">IC No.: </span>{fields["identity_card"].value.license_no}</div>

                              </div>
                            )}

                            <FormItem
                              validateStatus={
                                !fields["identity_card"].valid ? "error" : "validating"
                              }
                              help={this.state.error_message["identity_card"]}
                            />       
                          </div>
                          {/* END Identity Card */} 
                                    
                      </Form>
                    </div> 
                </div>
              </div>

              <ToastContainer autoClose={2000} />
            </SignUpStyleWrapper>
          

            <div className="buttonBottom">        
              <Button
                type="default"
                size="large"
                className="bottom-btn primary-btn"
                onClick={this._onSubmit}
                loading={isProcessSignupComplete}
              >
                Submit
              </Button>
            </div>
          </React.Fragment>
        )}
        
        {page === "upload_doc" && selectedDocType && (
          <ModalUploadDocument 
            visible={(page === "upload_doc")}
            doc_type={selectedDocType}
            documentation={(selectedDocType) ? fields[selectedDocType].value : {}} 
            uploadedFile={this.state.uploadedFile}
            onClickSelection={this.state.onClickSelection}
            isProcessUploadDocumentation={this.props.isProcessUploadDocumentation}
            isUploadDocumentationSuccess={this.props.isUploadDocumentationSuccess}
            isUploadDocumentationFailed={this.props.isUploadDocumentationFailed}
            onUploadDocumentation={this._uploadDocumentation}
            onUploadImageSelection={this._uploadImageSelection}
            onHandleFileChange={this._handleFileChange}
            onDismissSelection={this._onDismissSelection}
            resetUploadClick={this._onResetUploadClick}
            onSetValueUpdated={this._valueUpdated}
            field_errors={this.props.field_errors}
          />
        )}

        
        {screen !== "" && (
          <div className="ant-modal-root">
            <div className="ant-modal-mask"></div>
            <div className="ant-modal-wrap instagram-modal">
              <div className="ant-modal">
                <div className="ant-modal-content">
                  <div className="ant-modal-body">

                    {screen === "selection" && (
                      <div className="button-selection">

                        {view !== "DesktopView" && (
                          <Button
                            className="selection"
                            size="large"
                            type="default"
                            onClick={() => this._onSelectCamera()}
                          >
                            Camera
                          </Button>
                        )}
                        
                        <div className="mt-2">
                          <Button
                            className="selection"
                            size="large"
                            type="default"
                            onClick={() => this._onSelectPhotos()}
                          >
                            Photos
                          </Button>
                        </div>

                        <div className="mt-2">
                          <Button
                            className="bottom-btn grey-btn"
                            size="large"
                            type="default"
                            onClick={() => this._onDismissSelection()}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    )}

                    {screen === "discard_change_confirm" && (
                      <div>
                        <div className="pending-order-container mb-3">

                          {(page === "") && (
                            <div className="text-bold font-18">Dismiss Sign Up? </div>
                          )}

                          {(page === "upload_doc") && (
                            <div className="text-bold font-18">Dismiss Upload Document? </div>
                          )}
                        </div>

                        <div className="d-flex justify-content-between width100">                        

                          <Button
                            className="bottom-btn grey-btn mr-2"
                            size="large"
                            type="default"
                            onClick={() => this._proceedBack(true)}
                          >
                            Discard
                          </Button>

                          <Button
                            className="bottom-btn green-btn ml-2"
                            size="large"
                            type="default"
                            onClick={() => this._onContinue()}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}


        {/* Modal Invoice PDF */}
        <ModalPDFView
            view={this.props.view}
            visible={(this.state.modalPDFViewVisible && this.state.viewFileAction !== "" && this.state.viewFilePath) ? true : false}
            onDismiss={this._hideModalPDFView}
            onPrint={this._onPrint}
            data={{
              url:(this.state.viewFilePath && this.state.viewFileAction !== "") ? this.state.viewFilePath : ""
            }}
            
        />
      
        {/* END Modal Invoice PDF */}
      </CustomStyles>
    );
  }
}

const mapStateToProps = state => {
  const { profileData, isProcessSignupComplete, isSignupCompleteSuccess, signup_verify_email, isSignupCompleteFailed, proceedSignupUploadDocument } = state.Auth;
  const { isUploadDocumentationSuccess, isUploadDocumentationFailed, isProcessUploadDocumentation, graderDocuments, isLoadGraderDocumentationSuccess, isLoadGraderDocumentationFailed, isProcessLoadGraderDocumentation } = state.Grader;
  const { field_errors, view} = state.App;
  
  return { profileData, field_errors, view, isProcessSignupComplete, isSignupCompleteSuccess, signup_verify_email, isSignupCompleteFailed, proceedSignupUploadDocument, isUploadDocumentationSuccess, isUploadDocumentationFailed, isProcessUploadDocumentation, graderDocuments, isLoadGraderDocumentationSuccess, isLoadGraderDocumentationFailed, isProcessLoadGraderDocumentation };

};

const mapDispatchToProps = dispatch => {
  return {
    
    // onLoadMyProfile: () => dispatch(actions.getMyProfile()),
    onUploadDocumentation: params => dispatch(actions.uploadDocumentation(params)),
    onLoadGraderDocumentation: params => dispatch(actions.loadGraderDocumentation(params)),
    onCompleteSignup: () => dispatch(actions.completeSignup()),
    onDismissRegister: () => dispatch(actions.dismissRegistration()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(SignupDocument);
