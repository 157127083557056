const SITE_CONFIG = {
  siteName: "Dumpster Admin",
  siteIcon: "ion-flash",
  footerText: "Dumpster Sdn. Bhd. © 2019",
  version: "v1.0"
};

const THEME_CONFIG = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault"
};
const LANGUAGE = "english";

const JWT_CONFIG = {
  fetchUrl: "/api/",
  secretKey: "secretKey"
};


const API_HOST = {
  Live: "https://api-live.dumpster.com.my",
  Stage: "https://api-test.dumpster.com.my",
  Dev: "https://api-dev.dumpster.com.my",
  // Stage: "https://api-dev.dumpster.com.my",
  // Stage: "http://192.168.1.16:3004", 
};

const COUNTRY_CODE = [{ name: "Malaysia", dial_code: "+60", code: "MY" }];

//const CROSS_ORIGIN = "https://cors-anywhere.herokuapp.com";
//const CROSS_ORIGIN = "http://110.4.47.165:4054";
const CROSS_ORIGIN = "https://cors.dumpster.com.my"

const GOOGLE_API_KEY = {
  Live: "AIzaSyD357ICT4bcuYny3eXRSSx5xnoXZPO2TWU",
  Stage: "AIzaSyDsItAs5mKnu7cE0dGZgRkB1L_xVWT1icI",
  Dev: "AIzaSyDsItAs5mKnu7cE0dGZgRkB1L_xVWT1icI",
};

const FPX_STATUS_WEB_URL = {
  Live: "https://www.dumpster.com.my/Payment/FPX/fpx_status.php",
  Stage: "https://www.dumpster.com.my/Private/FPX/fpx_status.php",
  Dev: "https://www.dumpster.com.my/Private/FPX/fpx_status.php",
};

const FPX_SERVICE_STATUS_TRACK_URL = {
  Live: "https://www.dumpster.com.my/Payment/FPX/fpx_service_status.php",
  Stage: "https://www.dumpster.com.my/Private/FPX/fpx_service_status.php",
  Dev: "https://www.dumpster.com.my/Private/FPX/fpx_service_status.php",
};

const userFirebaseConfig = {
  Live: {
    apiKey: "AIzaSyAK3cBT1pDTFB1u-zr3oZnVrrlJuZ6vAqE",
    authDomain: "dumpster-driver-app-9c4e9.firebaseapp.com",
    databaseURL: "https://dumpster-driver-app-9c4e9.firebaseio.com",
    projectId: "dumpster-driver-app-9c4e9",
    storageBucket: "dumpster-driver-app-9c4e9.appspot.com",
    messagingSenderId: "836888483946",
    appId: "1:836888483946:web:526368142cf1e09ccf5213",
    measurementId: "G-5RC007RWFK"
  },
  // Stage: {
  //   apiKey: "AIzaSyDxi6oPHRS2X2HhaLzZm3aielcRgN73umI",
  //   authDomain: "dumpster-user-app-stage.firebaseapp.com",
  //   databaseURL: "https://dumpster-user-app-stage.firebaseio.com",
  //   projectId: "dumpster-user-app-stage",
  //   storageBucket: "dumpster-user-app-stage.appspot.com",
  //   messagingSenderId: "556477196384",
  //   appId: "1:556477196384:web:ba656f347e485822de3b05",
  //   measurementId: "G-1L9TG4LW6F"
  // },
  Stage: {
    apiKey: "AIzaSyA4XATkJYH6I0uRzSN1ZS2dmMyo9ZisP4g",
    authDomain: "dumpster-user-stage.firebaseapp.com",
    databaseURL: "https://dumpster-user-stage-default-rtdb.firebaseio.com",
    projectId: "dumpster-user-stage",
    storageBucket: "dumpster-user-stage.appspot.com",
    messagingSenderId: "610650100041",
    appId: "1:610650100041:web:98c4b5388ad16c745eca98",
    measurementId: "G-R3QW9WL379"
  }
};

const WHATSAPP_WEB_SOCKET = {
  Live: "https://whatsapp.dumpster.com.my/",
  Stage: "https://whatsapp-stage.dumpster.com.my/",
  Dev: "https://whatsapp-stage.dumpster.com.my/",
  // Dev: "http://192.168.1.12:3003/", 
};

// const GRADER_VERSION = "V1"
const GRADER_VERSION = "V2"

const APP_VERSION = "1.4.18"
// const APP_ENV = "Stage"

// const APP_VERSION = "1.3.21"
const APP_ENV = "Live"

const VIEW_PRICE = true

export {
  SITE_CONFIG,
  LANGUAGE,
  THEME_CONFIG,
  JWT_CONFIG,
  API_HOST,
  GOOGLE_API_KEY,
  COUNTRY_CODE,
  CROSS_ORIGIN,
  userFirebaseConfig,
  APP_VERSION,
  APP_ENV,
  FPX_STATUS_WEB_URL,
  FPX_SERVICE_STATUS_TRACK_URL,
  WHATSAPP_WEB_SOCKET,
  VIEW_PRICE,
  GRADER_VERSION
};
