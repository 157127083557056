import React, { Component } from "react";
import "react-dates/initialize";
import moment from "moment";
import { cloneDeep } from "lodash";


// Component
import Form from "../../../components/uielements/form";
import Input from "../../../components/uielements/input";
import Button from "../../../components/uielements/button";
import Color from "../../../assets/Color";
import Icon from "../../../assets/components/Icon";
import DatePicker from "../../../components/uielements/datePicker";
import { Document, Page } from 'react-pdf';

import Validator, { validateField, validateForm } from "../../../helpers/validation";

import SignUpStyleWrapper, { CustomStyles } from "./style";

const FormItem = Form.Item;

const defaultData = {
  fields: {
    doc_type: {
      value: "",
      validation: {
        rules: {},
        error_message: {}
      },
      valid: true
    },
    identity_card_doc: {
      value: null,
      validation: {
        rules: {},
        error_message: {}
      },
      valid: true
    },
    identity_card_url: {
      value: "",
      validation: {
        rules: { required: false },
      },
      valid: true
    },
    ic_no: {
      value: "",
      validation: {
        rules: {},
        error_message: {}
      },
      valid: true
    },
    police_license_doc: {
      value: null,
      validation: {
        rules: {},
        error_message: {}
      },
      valid: true
    },
    police_license: {
      value: null,
      validation: {
        rules: {},
        error_message: {}
      },
      valid: true
    },
    police_license_valid_until: {
      value: null,
      validation: {
        rules: {},
        error_message: {}
      },
      valid: true
    },
    local_authority_license_doc: {
      value: null,
      validation: {
        rules: {},
        error_message: {}
      },
      valid: true
    },
    local_authority_license: {
      value: null,
      validation: {
        rules: {},
        error_message: {}
      },
      valid: true
    },
    local_authority_license_valid_until: {
      value: null,
      validation: {
        rules: {},
        error_message: {}
      },
      valid: true
    },
    ssm_doc: {
      value: null,
      validation: {
        rules: {},
        error_message: {}
      },
      valid: true
    },
    ssm_valid_until: {
      value: null,
      validation: {
        rules: {},
        error_message: {}
      },
      valid: true
    },
  },
  error_message: {},
};

class ModalUploadDocument extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ...defaultData,
      error: {},
    };

    this.state.fields.identity_card_doc.validation["additional"] = {
      validValue: {
        rules: this._validateICRequired,
        error_message: "Please Upload Identity Card"
      }
    }

    this.state.fields.ic_no.validation["additional"] = {
      validValue: {
        rules: this._validateICRequired,
        error_message: "Please Enter IC No."
      }
    }

    this.state.fields.ssm_doc.validation["additional"] = {
      validValue: {
        rules: this._validateSSMRequired,
        error_message: "Please Upload SSM"
      },
    }

    this.state.fields.ssm_valid_until.validation["additional"] = {
      required: {
        rules: this._validateSSMRequired,
        error_message: "Please Enter License Expiry Date"
      },
      dateValid: {
        rules: this._validateLicenseDateValid,
        error_message: "License Expiry Date Invalid"
      }
    }


    this.state.fields.police_license.validation["additional"] = {
      validValue: {
        rules: this._validatePoliceLicenseRequired,
        error_message: "Please Enter License No."
      },
    }

    this.state.fields.police_license_doc.validation["additional"] = {
      validValue: {
        rules: this._validatePoliceLicenseRequired,
        error_message: "Please Upload Police License"
      },
    }

    this.state.fields.police_license_valid_until.validation["additional"] = {
      required: {
        rules: this._validatePoliceLicenseRequired,
        error_message: "Please Enter License Expiry Date"
      },
      dateValid: {
        rules: this._validateLicenseDateValid,
        error_message: "License Expiry Date Invalid"
      }
    }


    this.state.fields.local_authority_license.validation["additional"] = {
      validValue: {
        rules: this._validateLocalAuthorityLicenseRequired,
        error_message: "Please Enter License No."
      },
    }

    this.state.fields.local_authority_license_doc.validation["additional"] = {
      validValue: {
        rules: this._validateLocalAuthorityLicenseRequired,
        error_message: "Please Upload Vocational License"
      },
    }

    this.state.fields.local_authority_license_valid_until.validation["additional"] = {
      required: {
        rules: this._validateLocalAuthorityLicenseRequired,
        error_message: "Please Enter License Expiry Date"
      },
      dateValid: {
        rules: this._validateLicenseDateValid,
        error_message: "License Expiry Date Invalid"
      }
    }

  }

  componentDidMount() {

    const { documentation } = this.props

    if(documentation && documentation.id)
    {
      this._updateStateData()
    }

  }
  

  componentDidUpdate(prevProps) {
    
    const { field_errors, documentation } = this.props
    
    if (
      JSON.stringify(prevProps.field_errors) !== JSON.stringify(field_errors)
    ) {
      this._updateStateError(field_errors);
    }
    
    if (this.props.visible !== prevProps.visible) {
      this.setState({ ...defaultData });
    }

    
    if (this.props.uploadedFile !== prevProps.uploadedFile && this.props.uploadedFile) {
      this._handleFileChange(this.props.uploadedFile.file, this.props.uploadedFile.name);
    }
    
    if (this.props.onClickSelection !== prevProps.onClickSelection && this.props.onClickSelection) {

      if(this[this.props.onClickSelection])
      {
        this[this.props.onClickSelection].click()
        this.props.resetUploadClick()
      }
    }
    
    if(documentation !== prevProps.documentation && documentation)
    {
      this._updateStateData()
    }
  }
  
  _updateStateData = () => {
    const {doc_type, documentation} = this.props
    let fields = cloneDeep(this.state.fields);
    
    if(doc_type === "police_license")
    {
      fields["police_license"].value = documentation.license_no
      fields["police_license_valid_until"].value = documentation.valid_until
      fields["police_license_doc"].value = {file: documentation.file, originalname: documentation.doc_detail.originalname}
    }
    
    if(doc_type === "local_authority_license")
    {
      fields["local_authority_license"].value = documentation.license_no
      fields["local_authority_license_valid_until"].value = documentation.valid_until
      fields["local_authority_license_doc"].value = {file: documentation.file, originalname: documentation.doc_detail.originalname}
    }
    
    if(doc_type === "ssm")
    {
      fields["ssm_doc"].value = {file: documentation.file, originalname: documentation.doc_detail.originalname}
      fields["ssm_valid_until"].value = documentation.valid_until
    }
    
    if(doc_type === "identity_card")
    {
      fields["ic_no"].value = documentation.license_no
      fields["identity_card_doc"].value = {file: documentation.file, originalname: documentation.doc_detail.originalname}

      fields["identity_card_url"].value = documentation.file
      
    }
    
    this.setState({ fields});
  };

  _updateStateError = (errors) => {
    let error_message = cloneDeep(this.state.error_message);
    let fields = cloneDeep(this.state.fields);
    
    errors.map(e => {
      
      if(fields[e.param])
      {
        fields[e.param].valid = false
        error_message[e.param] = e.msg
      }

      return null;
    })
    
    this.setState({ error_message, fields});
  };

  _validateICRequired = value => {
    
    let isValid = true;
    const { doc_type } = this.props

    if (doc_type === "identity_card") {
      let validator = Validator({ rules: { required: true } }, value);
      isValid = validator.valid;
    }

    return isValid;
  };

  _validateLicenseDateValid = value => {
    
    let isValid = true;

    if (value !== "")       
      isValid = moment(value).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD");
    
    return isValid;
  };

  _validateSSMRequired = value => {
    
    let isValid = true;
    const { doc_type } = this.props

    if (doc_type === "ssm") {
      let validator = Validator({ rules: { required: true } }, value);
      isValid = validator.valid;
    }

    return isValid;
  };

  _validatePoliceLicenseRequired = value => {
    
    let isValid = true;
    const { doc_type } = this.props

    if (doc_type === "police_license") {
      let validator = Validator({ rules: { required: true } }, value);
      isValid = validator.valid;
    }

    return isValid;
  };

  _validateLocalAuthorityLicenseRequired = value => {
    
    let isValid = true;
    const { doc_type } = this.props

    if (doc_type === "local_authority_license") {
      let validator = Validator({ rules: { required: true } }, value);
      isValid = validator.valid;
    }

    return isValid;
  };




  _handleChange = (e, value = "", a = "") => {
    const { isProcessUploadDocumentation } = this.props;
    let fields = cloneDeep(this.state.fields);
    let name = "";
    
    if (typeof e === "string") {
      name = e;
    } else {
      name = e.target.name;

      switch (e.target.type) {
        case "checkbox":
          value = e.target.checked;
          break;
        default:
          value = e.target.value;
          break;
      }
    }
    
    if(!isProcessUploadDocumentation)
    {
      if(["ic_no"].includes(name))
      {     
        if(value.length <= 12)
          fields[name].value = value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');

      }else
        fields[name].value = value;
      
      this.setState({ fields}, () => {
        this._handleBlurr(name);
        this.props.onSetValueUpdated()
      });
    }
  };

  _handleBlurr = e => {
    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    let name = "";

    if (typeof e === "string") {
      name = e;
    } else {
      name = e.target.name;
    }

    let validator = validateField(fields, name, error_message);

    this.setState({ ...validator });
  };

  _onSubmit = async () => {
    const { doc_type } = this.props;
    
    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    let validator = await validateForm(fields, error_message);
    
    this.setState({ ...validator.data }, () => {
      if (validator.valid) {
        
        let params = new FormData();
        params.append("doc_type", doc_type);
               
        switch (doc_type)
        {
          case 'identity_card':
            
            params.append("ic_no", fields["ic_no"].value);
            params.append("identity_card_doc", fields["identity_card_doc"].value.file);
          break;

          case 'police_license':
            
            params.append("police_license_doc", fields["police_license_doc"].value.file);
            params.append("police_license", fields["police_license"].value);
            params.append("police_license_valid_until", fields["police_license_valid_until"].value);
          break;

          case 'local_authority_license':
            
            params.append("local_authority_license_doc", fields["local_authority_license_doc"].value.file);
            params.append("local_authority_license", fields["local_authority_license"].value);
            params.append("local_authority_license_valid_until", fields["local_authority_license_valid_until"].value);
          break;

          case 'ssm':
            
            params.append("ssm_doc", fields["ssm_doc"].value.file);
            params.append("ssm_valid_until", fields["ssm_valid_until"].value);
          break;
          
          default:
            break;

        }

        this.props.onUploadDocumentation(params);
        
      }
    });

  };

  
  /* ===== File ===== */
  
  _onUploadImageSelection = () => {

    const { isProcessUploadDocumentation } = this.props;

    if(!isProcessUploadDocumentation)
      this.props.onUploadImageSelection()
    
  };

  _onHandleFileChange = (e, doc) => {

    const { isProcessUploadDocumentation } = this.props;

    if(!isProcessUploadDocumentation)
      this.props.onHandleFileChange(e, doc)
    
  };


  _handleFileChange = (file, doc) => {

    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);

    fields[doc].value = {file: file[0], originalname: file[0].name}

    this.setState({ fields }, () => {

      this.props.onSetValueUpdated()
      
      if(doc === "identity_card_doc")
      {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          
            let fields = cloneDeep(this.state.fields);          
            fields["identity_card_url"].value = reader.result;
  
            this.setState({ fields }, () => {

              this.props.onDismissSelection();
            });
          
        });
        reader.readAsDataURL(file[0]);
      }

      let validator = validateField(fields, doc, error_message)

      this.setState({ ...validator });
    });
  };

  _removeFile = (doc) => {

    let fields = cloneDeep(this.state.fields);
    fields[doc].value = null

    this.setState({ fields });
  };

  renderLoader = () => {
    
    return (<div className="list-loading">
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>)
  }
  /* ===== END File ===== */



  render() {
    
    const { doc_type, isProcessUploadDocumentation } = this.props;
    const { fields } = this.state;
    
    return (
      <CustomStyles>
        <SignUpStyleWrapper className="isoSignInPage"  id="container-top">
          
          <div className="isoLoginContentWrapper">
            <div className="isoLoginContent">

              {doc_type === "identity_card" && (

                <React.Fragment>

                  {/* License Doc */}      
                  <div className="font-11 color-darkgrey d-flex justify-content-between align-middle" id="identity_card_doc">
                    <div>Identity Card (*.image)</div>
                    <div>
                      {fields["identity_card_url"].value && (
                        <Button
                          type="default"
                          size="small"
                          className="bottom-btn small primary-btn"
                          onClick={() => this._onUploadImageSelection()}
                        >
                          Replace
                        </Button>
                      )}        
                    </div>
                  </div>                                  
                  <FormItem
                    validateStatus={
                      !fields["identity_card_doc"].valid ? "error" : "validating"
                    }
                    help={this.state.error_message["identity_card_doc"]}
                  >
                    <div className="isoImageCon pt-1">       
                    
                      <input
                        type="file"
                        name="uploadIC"
                        onChange={e => this._onHandleFileChange(e, "identity_card_doc")}
                        accept="image/*"
                        ref={ref => {
                          this.uploadIC = ref;
                        }}
                        className="hidden"
                      />
                      
                      <input 
                        type="file" 
                        name="captureIC"
                        accept="image/*" 
                        capture="environment"
                        onChange={e => { this._onHandleFileChange(e, "identity_card_doc"); }}
                        ref={ref => {
                          this.captureIC = ref;
                        }}
                        className="hidden"
                      />

                      {fields["identity_card_url"].value ? (

                        <div 
                          className="img-wrapper"            
                          // onClick={() => this._onViewDocument(fields["identity_card_url"].value, "grader_detail")}
                        > 
                          <img alt="" className="document-box" src={fields["identity_card_url"].value} />
                                        
                          <div className="text-center font-bold text-underline">{ (fields["identity_card_doc"].value.originalname.length > 32) ? `${fields["identity_card_doc"].value.originalname.slice(0, 30)}...` : fields["identity_card_doc"].value.originalname}</div>
                        </div>
                      ) : (
                        <React.Fragment>
                          
                          <div className="image-wrapper">      
                            <div
                              className="isoIcon"
                              onClick={() => this._onUploadImageSelection()}
                            >     
                              <div className="pt-2">             
                                <Icon
                                  type="custom"
                                  name="upload"
                                  color={Color.Secondary}
                                  size={25}
                                />
                                <div className="font-bold">Upload IC (*.image)</div>
                              </div> 
                            </div>
                        </div>
                        </React.Fragment>
                      )}
                    </div>      
                  </FormItem>                                  
                  {/* END License Doc*/}
                  
                  {/* Identity No. */}
                  <div className="font-11 color-darkgrey">Identity No.</div>
                  <FormItem
                    validateStatus={
                      !fields["ic_no"].valid ? "error" : "validating"
                    }
                    help={this.state.error_message["ic_no"]}
                  >
                    <Input
                      size="large"
                      type="text"
                      name="ic_no"
                      placeholder="Enter IC No..."  
                      value={fields["ic_no"].value}
                      autoComplete="off"
                      onChange={event => this._handleChange("ic_no", event.target.value)}
                      onBlur={value => this._handleBlurr("ic_no", value)} 
                    />
                  </FormItem>
                  {/* END Identity No. */}
                  
                </React.Fragment>
              )}


              {doc_type === "police_license" && (

                <React.Fragment>

                  {/* License Doc */}                      
                  <div className="font-11 color-darkgrey d-flex justify-content-between align-middle" id="police_license_doc">
                    <div>Police License (*.pdf)</div>
                    <div>
                      {fields["police_license_doc"].value && (
                        <Button
                          type="default"
                          size="small"
                          className="bottom-btn small primary-btn"
                          onClick={() => this.addPL.click()}
                        >
                          Replace
                        </Button>
                      )}        
                    </div>
                  </div>                     
                  <FormItem
                    validateStatus={
                      !fields["police_license_doc"].valid ? "error" : "validating"
                    }
                    help={this.state.error_message["police_license_doc"]}
                  >
                    <div className="isoImageCon pt-1">       
                    
                      <input
                        type="file"
                        name="addPL"
                        accept="application/pdf"
                        className="hidden"
                        onChange={e => { this._onHandleFileChange(e, "police_license_doc"); }}
                        ref={ref => { this.addPL = ref; }}
                      />

                      {fields["police_license_doc"].value ? (

                        <div className="file-wrapper">                                      
                          
                          <Document
                            file={fields["police_license_doc"].value.file}
                            className="document-box"
                            loading={this.renderLoader()}
                          >
                            <Page pageNumber={1}/>
                          </Document>

                          <div className="text-center font-bold text-underline">{ (fields["police_license_doc"].value.originalname.length > 32) ? `${fields["police_license_doc"].value.originalname.slice(0, 30)}...` : fields["police_license_doc"].value.originalname}</div>
                          
                        </div>
                      ) : (
                        <React.Fragment>
                          
                          <div className="image-wrapper">      
                            <div
                              className="isoIcon"
                              onClick={() => this.addPL.click()}
                            >     
                              <div className="pt-2">             
                                <Icon
                                  type="custom"
                                  name="upload"
                                  color={Color.Secondary}
                                  size={25}
                                />
                                <div className="font-bold">Upload File (.pdf)</div>
                              </div> 
                            </div>
                        </div>
                        </React.Fragment>
                      )}
                    </div>   
                  </FormItem>                                  
                  {/* END License Doc*/}
                  
                  {/* Police License */}
                  <div className="font-11 color-darkgrey">License No.</div>
                  <FormItem
                    validateStatus={
                      !fields["police_license"].valid ? "error" : "validating"
                    }
                    help={this.state.error_message["police_license"]}
                  >
                    <Input
                      size="large"
                      type="text"
                      name="police_license"
                      placeholder="Enter License No..."  
                      value={fields["police_license"].value}
                      autoComplete="off"
                      onChange={event => this._handleChange("police_license", event.target.value)}
                      onBlur={value => this._handleBlurr("police_license", value)} 
                    />
                  </FormItem>
                  {/* END Police License */}

                  {/* License Expiry Date */}
                  <div className="font-11 color-darkgrey">License Expiry Date</div>
                  <FormItem
                    validateStatus={
                      !fields["police_license_valid_until"].valid ? "error" : "validating"
                    }
                    help={this.state.error_message["police_license_valid_until"]}
                  >
                    <DatePicker
                      name="police_license_valid_until"
                      className="date-filter"
                      format="YYYY-MM-DD"
                      disabledDate ={(momentDate) => momentDate.format('Y-MM-DD') <= moment().format('Y-MM-DD')}
                      value={(fields["police_license_valid_until"].value) ? moment(fields["police_license_valid_until"].value, "YYYY-MM-DD") : null}
                      onChange={value => this._handleChange("police_license_valid_until", value)}
                      onBlur={value => this._handleBlurr("police_license_valid_until", value)} 
                    />
                  </FormItem>
                  {/* END License Expiry Date */}
                  
                </React.Fragment>
              )}


              {doc_type === "local_authority_license" && (

                <React.Fragment>

                  {/* License Doc */}                                   
                  <div className="font-11 color-darkgrey d-flex justify-content-between align-middle" id="local_authority_license_doc">
                    <div>Local Authority License (*.pdf)</div>
                    <div>
                      {fields["local_authority_license_doc"].value && (
                        <Button
                          type="default"
                          size="small"
                          className="bottom-btn small primary-btn"
                          onClick={() => this.addLAL.click()}
                        >
                          Replace
                        </Button>
                      )}        
                    </div>
                  </div>                          
                  <FormItem
                    validateStatus={
                      !fields["local_authority_license_doc"].valid ? "error" : "validating"
                    }
                    help={this.state.error_message["local_authority_license_doc"]}
                  >
                    <div className="isoImageCon pt-1">       
                    
                      <input
                        type="file"
                        name="addLAL"
                        accept="application/pdf"
                        className="hidden"
                        onChange={e => { this._onHandleFileChange(e, "local_authority_license_doc"); }}
                        ref={ref => { this.addLAL = ref; }}
                      />

                      {fields["local_authority_license_doc"].value ? (

                        <div className="file-wrapper">                                      
                          
                          <Document
                            file={fields["local_authority_license_doc"].value.file}
                            className="document-box"
                            loading={this.renderLoader()}
                          >
                            <Page pageNumber={1}/>
                          </Document>

                          <div className="text-center font-bold text-underline">{ (fields["local_authority_license_doc"].value.originalname.length > 32) ? `${fields["local_authority_license_doc"].value.originalname.slice(0, 30)}...` : fields["local_authority_license_doc"].value.originalname}</div>
                          
                        </div>
                      ) : (
                        <React.Fragment>
                          
                          <div className="image-wrapper">      
                            <div
                              className="isoIcon"
                              onClick={() => this.addLAL.click()}
                            >     
                              <div className="pt-2">             
                                <Icon
                                  type="custom"
                                  name="upload"
                                  color={Color.Secondary}
                                  size={25}
                                />
                                <div className="font-bold">Upload File (.pdf)</div>
                              </div> 
                            </div>
                        </div>
                        </React.Fragment>
                      )}
                    </div>     
                  </FormItem>                                  
                  {/* END License Doc*/}
                  
                  {/* Local Authority License */}
                  <div className="font-11 color-darkgrey">License No.</div>
                  <FormItem
                    validateStatus={
                      !fields["local_authority_license"].valid ? "error" : "validating"
                    }
                    help={this.state.error_message["local_authority_license"]}
                  >
                    <div className="pt-1">
                      <Input
                        size="large"
                        type="text"
                        name="local_authority_license"
                        placeholder="Enter License No..."  
                        value={fields["local_authority_license"].value}
                        autoComplete="off"
                        onChange={event => this._handleChange("local_authority_license", event.target.value)}
                        onBlur={value => this._handleBlurr("local_authority_license", value)} 
                      />
                      </div>
                  </FormItem>
                  {/* END Local Authority License */}

                  {/* License Expiry Date */}
                  <div className="font-11 color-darkgrey">License Expiry Date</div>
                  <FormItem
                    validateStatus={
                      !fields["local_authority_license_valid_until"].valid ? "error" : "validating"
                    }
                    help={this.state.error_message["local_authority_license_valid_until"]}
                  >
                    <DatePicker
                      name="local_authority_license_valid_until"
                      className="date-filter"
                      format="YYYY-MM-DD"
                      disabledDate ={(momentDate) => momentDate.format('Y-MM-DD') <= moment().format('Y-MM-DD')}
                      value={(fields["local_authority_license_valid_until"].value) ? moment(fields["local_authority_license_valid_until"].value, "YYYY-MM-DD") : null}
                      onChange={value => this._handleChange("local_authority_license_valid_until", value)}
                      onBlur={value => this._handleBlurr("local_authority_license_valid_until", value)} 
                    />
                  </FormItem>
                  {/* END License Expiry Date */}
                  
                </React.Fragment>
              )}


              {doc_type === "ssm" && (

                <React.Fragment>

                  {/* License Doc */}                                  
                  <div className="font-11 color-darkgrey d-flex justify-content-between align-middle" id="ssm_doc">
                    <div>SSM (*.pdf)</div>
                    <div>
                      {fields["ssm_doc"].value && (
                        <Button
                          type="default"
                          size="small"
                          className="bottom-btn small primary-btn"
                          onClick={() => this.addSSM.click()}
                        >
                          Replace
                        </Button>
                      )}        
                    </div>
                  </div>                                          
                  <FormItem
                    validateStatus={
                      !fields["ssm_doc"].valid ? "error" : "validating"
                    }
                    help={this.state.error_message["ssm_doc"]}
                  >
                    <div className="isoImageCon pt-1">       
                    
                      <input
                        type="file"
                        name="addSSM"
                        accept="application/pdf"
                        className="hidden"
                        onChange={e => { this._onHandleFileChange(e, "ssm_doc"); }}
                        ref={ref => { this.addSSM = ref; }}
                      />

                      {fields["ssm_doc"].value ? (

                        <div className="file-wrapper">                                      
                          
                          <Document
                            file={fields["ssm_doc"].value.file}
                            className="document-box"
                            loading={this.renderLoader()}
                          >
                            <Page pageNumber={1}/>
                          </Document>

                          <div className="text-center font-bold text-underline">{ (fields["ssm_doc"].value.originalname.length > 32) ? `${fields["ssm_doc"].value.originalname.slice(0, 30)}...` : fields["ssm_doc"].value.originalname}</div>
                          
                        </div>
                      ) : (
                        <React.Fragment>
                          
                          <div className="image-wrapper">      
                            <div
                              className="isoIcon"
                              onClick={() => this.addSSM.click()}
                            >     
                              <div className="pt-2">             
                                <Icon
                                  type="custom"
                                  name="upload"
                                  color={Color.Secondary}
                                  size={25}
                                />
                                <div className="font-bold">Upload File (.pdf)</div>
                              </div> 
                            </div>
                        </div>
                        </React.Fragment>
                      )}
                    </div>      
                  </FormItem>                                  
                  {/* END License Doc*/}

                  {/* License Expiry Date */}
                  <div className="font-11 color-darkgrey">License Expiry Date</div>
                  <FormItem
                    validateStatus={
                      !fields["ssm_valid_until"].valid ? "error" : "validating"
                    }
                    help={this.state.error_message["ssm_valid_until"]}
                  >
                    <DatePicker
                      name="ssm_valid_until"
                      className="date-filter"
                      format="YYYY-MM-DD"
                      disabledDate ={(momentDate) => momentDate.format('Y-MM-DD') <= moment().format('Y-MM-DD')}
                      value={(fields["ssm_valid_until"].value) ? moment(fields["ssm_valid_until"].value, "YYYY-MM-DD") : null}
                      onChange={value => this._handleChange("ssm_valid_until", value)}
                      onBlur={value => this._handleBlurr("ssm_valid_until", value)} 
                    />
                  </FormItem>
                  {/* END License Expiry Date */}
                  
                </React.Fragment>
              )}
            </div>
          </div>

        </SignUpStyleWrapper>
        

        <div className="buttonBottom">        
          <Button
            type="default"
            size="large"
            className="bottom-btn primary-btn"
            onClick={this._onSubmit}
            loading={isProcessUploadDocumentation}
          >
            Submit
          </Button>
        </div>
        
      </CustomStyles>
    );
  }
}

export default ModalUploadDocument;
