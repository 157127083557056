import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import AppRouter from "./AppRouter";
import themes from "../../settings/themes";
import { THEME_CONFIG } from "../../settings";
import AppHolder from "./commonStyle";
import Spin from "../../components/uielements/spin";
import * as actions from "../../redux/actions";
import { SetAxiosDefaultConfig } from "../../redux/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./global.css";
import logo from "../../assets/icon/mascot_dump.png";
import moment from "moment";
// import Button from "../../components/uielements/button";


const { Content } = Layout;
 
export class App extends Component {

  state = {
    popUpNewVersionTms: null,
    popUpNewVersionVer: null,
  };

  async componentDidMount() {
    
    SetAxiosDefaultConfig();
    
    this.props.onLoadMyProfile();
    
  }

  componentWillUnmount() {
    clearInterval(this.reloginInterval);
    
  }

  componentDidUpdate(prevProps) {
    
    // const { profileData, app } = this.props
    // let isLive = (app.env === "Live") ? true : false
    const { isDeviceRegistered } = this.props;

    if (isDeviceRegistered && prevProps.location.pathname !== this.props.location.pathname) {
      //this.props.onLoadAMyProfile();
      this.props.onCheckVersion();
    }

    // if (JSON.stringify(prevProps.profileData) !== JSON.stringify(profileData) &&
    //   JSON.stringify(profileData) !== "{}"
    // ) {
    //     Firebase.latest_time(profileData.admin_id, isLive).on("value", snap =>{
        
    //       console.log("Received New Order Latest Time", snap.val())
    //       this.props.onUpdateLatestTime(snap.val());
    //     })
    // }
  }

  _onReloginInterval = () => {
    // Call relogin every one hour
    this.reloginInterval = setInterval(() => {
      this._onRelogin();
    }, 3600000);
  };

  _onRelogin = () => {
    this.props.onRefreshJWTToken();
  };

  _onDismissPopUp = () => {

    const { latestVersion } = this.props;

    this.setState({popUpNewVersionTms: moment().format("YYYY-MM-DD"), popUpNewVersionVer: latestVersion})
    
  };

  render() {
    const { url } = this.props.match;
    const { height, profileData, newContentAvailable, versionForceUpdate, latestVersion, location } = this.props;
    const { popUpNewVersionTms, popUpNewVersionVer } = this.state;
    const appHeight = window.innerHeight;

    // console.log({location})

    let style = {height: height}

    if(["/"].includes(location.pathname))
      style.overflow = "hidden"

    // Firebase.onMessageListener()
    // .then((payload) => {
    //     const { title, body } = payload.data;
    //     toast.info(`${title}; ${body}`);
    //   })
    //   .catch((err) => {
    //     toast.error(JSON.stringify(err));
    //   });

    return (
      <ThemeProvider theme={themes[THEME_CONFIG.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={windowSize =>
                  this.props.onToggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            
              <Layout
                className="isoContentMainLayout"
                style={style}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    flexShrink: "0",
                    position: "relative"
                  }}
                >
                  {/* {newContentAvailable && versionForceUpdate && (
                    <div className="version-upd-notif ant-modal-root">
                      <div className="ant-modal-mask"></div>
                      <div className="ant-modal-wrap instagram-modal">
                        <div className="ant-modal">
                          <div className="ant-modal-content">
                            <div className="ant-modal-body">
                              
                                <div className="mb-3">
                                  <img alt="" src={logo}/>
                                </div>

                                <div className="mb-3">
                                  <div className="text-bold font-18">New Version Available</div>
                                  <div className="pt-3">There is a newer version available for update! Please refresh in order to continue.</div>
                                </div>
                                
                                <div className="d-flex justify-content-between width100">                        
      
                                  <Button
                                    className="bottom-btn green-btn ml-2"
                                    size="large"
                                    type="default"
                                  >
                                    Ok
                                  </Button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}

                  {newContentAvailable && (!popUpNewVersionTms || (popUpNewVersionTms && (moment().format("YYYY-MM-DD") > moment(popUpNewVersionTms).format("YYYY-MM-DD") || popUpNewVersionVer !== latestVersion) )) &&(
                    <div className="version-popup-notif d-flex">
                              
                      <div className="align-middle">
                        <img alt="" src={logo}/>
                      </div>

                      <div className="ml-3">
                        <div className="text-bold font-18">New Version Available</div>
                        <div className="pt-2">There is a newer version available for update! Please refresh in order to continue.</div>
                      </div>

                      {!versionForceUpdate && (    
                        <div 
                          className="small padding0"
                          onClick={() => this._onDismissPopUp()}
                        >
                          <i className="ion-android-close"/>
                        </div>
                      )}     
                    </div>
                  )}
                  
                  {this.props.app.enableLoading && (
                    <div className="spin-loading" >
                      <div className='loader'>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                      </div>
                    </div>
                  )}

                  <Spin className="test" spinning={this.props.app.isLoading}>
                    <AppRouter url={url} profileData={profileData} />
                  </Spin>
                  <ToastContainer autoClose={2000} />
                </Content>
              </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    app: state.App,
    auth: state.Auth,
    height: state.App.height,
    profileData: state.Auth.profileData,
    newContentAvailable: state.Auth.newContentAvailable,
    versionForceUpdate: state.Auth.versionForceUpdate,
    latestVersion: state.Auth.latestVersion,
    isDeviceRegistered: state.Auth.isDeviceRegistered,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleAll: (width, height) => dispatch(actions.toggleAll(width, height)),
    onRefreshJWTToken: () => dispatch(actions.refreshJWTToken()),
    onLoadMyProfile: () => dispatch(actions.getMyProfile()),
    onCheckVersion: () => dispatch(actions.checkVersion()),    
    
    // onUpdateLatestTime: param => dispatch(actions.updateLatestTime(param)),

    // onUpdateOrderLog: param => dispatch(actions.updateOrderLog(param)),
    // onUpdateNewOrderCount: param => dispatch(actions.updateNewOrderCount(param)),
    // onUpdateNoDriverAcceptCount: param => dispatch(actions.updateNoDriverAcceptCount(param)),

    // onUpdateTripLog: param => dispatch(actions.updateTripLog(param)),
    // onUpdateUpcomingTrip: param => dispatch(actions.updateUpcomingTrip(param)),
    // onUpdateDriverNoComingCount: param => dispatch(actions.updateDriverNoComingCount(param)),

    
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
