import React, { Component } from "react";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Geocode from "react-geocode";

// Component
import Input, { InputGroup } from "../../../components/uielements/input";
import Select, { SelectOption } from "../../../components/uielements/select";
import GoogleMap from "../../../assets/components/GoogleMap";
import Form from "../../../components/uielements/form";
import Button from "../../../components/uielements/button";
import Icon from "../../../assets/components/Icon";
import Color from "../../../assets/Color";
import SignUpStyleWrapper, { MapStyle, CustomStyles } from "./style";
import * as actions from "../../../redux/actions";
import Validator, { validateField, validateForm } from "../../../helpers/validation";
import { COUNTRY_CODE } from "../../../settings";
import Modals from "../../../components/feedback/modal";

import ModalPDFView from "./ModalPDFView";
import ModalWarning from "./ModalWarning";
import defaultOrganization from "../../../assets/icon/default-organization.png";
import dumpExpress from "../../../assets/icon/dump-express.png";
import dumpRecycling from "../../../assets/icon/dump-recycling.png";
import dumpWaste from "../../../assets/icon/dump-waste.png";
import logo from "../../../assets/icon/mascot_dump.png";
import noResult from "../../../assets/icon/no-result.png";

// Config
import { GOOGLE_API_KEY } from "../../../settings";
import { store } from "../../../redux/store";


// UI Component

const Option = SelectOption;
const FormItem = Form.Item;

let env = store.getState().App.env;

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: {
          value: "",
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        full_name: {
          value: "",
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        grader_id_name: {
          value: "",
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        organization_name: {
          value: "",
          validation: {
            rules: {
              required: true,
            },
            error_message: {
              required: "Please enter organization name."
            }
          },
          valid: true
        },
        organization_countryCode: {
          value: "+60",
          validation: {
            rules: {
              required: true
            }
          },
          valid: true
        },
        organization_phoneNo: {
          value: "",
          validation: {
            rules: {
              required: true,
            },
            error_message: {
              required: "Please enter organization contact."
            }
          },
          valid: true
        },
        registration_no: {
          value: "",
          validation: {
            rules: {
              required: true,
            },
            error_message: {
              required: "Please enter registration no."
            }
          },
          valid: true
        },
        dump_express: {
          value: false,
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        dump_recycling: {
          value: false,
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        recycling_category: {
          value: [],
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        recycling_category_ids: {
          value: [],
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        dump_waste: {
          value: false,
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        dumpsters_service: {
          value: [],
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        dumpsters_service_ids: {
          value: [],
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        state: {
          value: "Melaka",
          validation: {
            rules: {
              required: true,
            },
            error_message: {
              required: "Please select state."
            }
          },
          valid: true
        },
        latitude: {
          value: "",
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        longitude: {
          value: "",
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        location_name: {
          value: "",
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        structured_formatting: {
          value: "",
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
        term_contition: {
          value: false,
          validation: {
            rules: {},
            error_message: {}
          },
          valid: true
        },
      },
      error_message: {},
      btnSubmited: false,
      page: "org_detail",
      locationConfirmed: false,
      search: "",
      searchLocation: false,
      currentLocation: {},
      modalPDFViewVisible: false,
      document:{},
      viewFilePath: "",
      viewFileAction: "",
      screen: "",
    };
  }

  componentDidMount() {
    
    Geocode.setApiKey(GOOGLE_API_KEY[env]);
    Geocode.enableDebug();

    let fields = cloneDeep(this.state.fields);

    fields.location_name.validation["additional"] = {
      required: {
        rules: this._validateLocationRequired,
        error_message: "Please Select Location."
      }
    }
    
    fields.email.validation["additional"] = {
      required: {
        rules: this._validateUserDetailRequired,
        error_message: "Please enter email address."
      }
    }
    
    fields.full_name.validation["additional"] = {
      required: {
        rules: this._validateUserDetailRequired,
        error_message: "Please enter name."
      }
    }
    
    fields.grader_id_name.validation["additional"] = {
      required: {
        rules: this._validateUserDetailRequired,
        error_message: "Please enter Grader ID."
      }
    }
    
    fields.dump_express.validation["additional"] = {
      required: {
        rules: this._validateServiceRequired,
        error_message: "Please select service."
      }
    }
    
    fields.recycling_category.validation["additional"] = {
      required: {
        rules: this._validateRecyclingCategoryRequired,
        error_message: "Please select recycling category."
      }
    }
    
    fields.dumpsters_service.validation["additional"] = {
      required: {
        rules: this._validateDumpstersServiceRequired,
        error_message: "Please select dumpster service."
      }
    }


    this.setState({ fields});

    this.props.onLoadStateDistrictList();    
    this.props.onLoadRecyclingList({state: fields["state"].value});
    this.props.onLoadDumpsterService();
  }
  
  
  componentDidUpdate(prevProps, nextProps) {
    
    const { field_errors, isSignupSuccess, signup_verify_email, isSignupFailed } = this.props; 

    if (
      JSON.stringify(prevProps.field_errors) !== JSON.stringify(field_errors)
    ) {

      this._updateStateError(field_errors);
    }  
    
    if (
      JSON.stringify(prevProps.isSignupFailed) !== JSON.stringify(isSignupFailed) && isSignupFailed
    ) {

      this.setState({btnSubmited: false});
    }  

    
    if (
      JSON.stringify(prevProps.isSignupSuccess) !== JSON.stringify(isSignupSuccess) && isSignupSuccess &&      
      JSON.stringify(prevProps.signup_verify_email) !== JSON.stringify(signup_verify_email) && signup_verify_email
    ) {

      this._signUpVerifyEmail();
    }  
    
  }


  _scrollToTop = (e) => {
    const element = document.getElementById(e);
    
    if (element) 
    element.scrollIntoView({ behavior: 'instant', block: "start", inline: "start" });
  };

  _proceedBack = (discard = false) => {

    const { page, searchLocation } = this.state;
    
    if(page === "org_detail")
    {
      if(discard) 
        this.props.onDismissRegister();
      
      else
        this.setState({screen: "discard_change_confirm"})

    }else if(page === "location" && searchLocation)
      this.setState({ searchLocation: false});

    else if(["category_selection", "service_selection"].includes(page))
    {      
      this.setState({ page: "user_detail"}, () => {

          this._scrollToTop("service-top")        
      });

    }else{
      
      this.setState({ page: "org_detail"}, () => {
        
        if(["location"].includes(page))
          this._scrollToTop("coordinate-top")
      });
    }
  }
  
  _onContinue = () => {
    
    this.setState({screen: ""})
    
  };
  
  _signUpVerifyEmail = () => {
          
    const handleOk = () => {

      this.props.onDismissRegister();
    };
      
    Modals.success({
      title: "Registration Successful!",
      content: "Thank you for registering with us. Your account is currently pending approval by the site administrator. As soon as your request is approved you will receive a confirmation email.",
      onOk() {
        handleOk();
      },
      okText: "OK",
    });

  }


  _validateLocationRequired = value => {

    const { locationConfirmed } = this.state
        
    return (value && locationConfirmed);
  };
  

  _validateUserDetailRequired = value => {

    const { page }  = this.state
    let isValid = true;
        
    if(page === "user_detail")
    {      
      let validator = Validator({ rules: { required: true } }, value);
      isValid = validator.valid;
    }

    return isValid;
  };
  
  _validateServiceRequired = value => {

    const { page }  = this.state
    let fields = cloneDeep(this.state.fields);
    let isValid = true;
        
    if(page === "user_detail" && !fields["dump_express"].value && !fields["dump_recycling"].value && !fields["dump_waste"].value)
    {      
      let validator = Validator({ rules: { required: true } }, value);
      isValid = validator.valid;
    }

    return isValid;
  };

  _validateRecyclingCategoryRequired = value => {

    let fields = cloneDeep(this.state.fields);
    let isValid = true;
        
    if(fields["dump_recycling"].value)
    {      
      let validator = Validator({ rules: { required: true } }, value);
      isValid = validator.valid;
    }

    return isValid;
  };

  _validateDumpstersServiceRequired = value => {

    let fields = cloneDeep(this.state.fields);
    let isValid = true;
        
    if(fields["dump_waste"].value)
    {      
      let validator = Validator({ rules: { required: true } }, value);
      isValid = validator.valid;
    }

    return isValid;
  };


  _updateStateError = (errors) => {
    let error_message = cloneDeep(this.state.error_message);
    let fields = cloneDeep(this.state.fields);
    let page = "user_detail";
    let extra_err = null
    let scrollTo = null

    errors.map(e => {

      if(["organization_name", "organization_contact", "state", "registration_no", "latitude", "longitude","location_name", "structured_formatting", ].includes(e.param))
        page = "org_detail"
    
      if(e.param === "organization_contact")
      {
        fields["organization_phoneNo"].valid = false
        error_message["organization_phoneNo"] = e.msg

      }else if(fields[e.param])
      {
        fields[e.param].valid = false
        error_message[e.param] = e.msg

      }else{
        extra_err = e.msg
      }

      scrollTo = e.param

      if(["dump_express", "dump_recycling", "dump_waste", "recycling_category", "dumpsters_service"].includes(e.param))
        scrollTo = "service-top"
      
      return null;
    })
    
    this.setState({ page, error_message, fields, btnSubmited: false }, () => {

      if(extra_err)
      {         
        Modals.error({
          title: "Error",
          content: extra_err,
          okText: "OK",
        });
      }

      if(scrollTo)
      {
        this._scrollToTop(scrollTo)
      }
    });
  };

  


  _onChangeServiceType = (key) => {

    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
        
    fields[key].value = !fields[key].value;

    this.setState({ fields }, () => {

      if(fields[key].value)
      {        
        let validator = validateField(fields, "dump_express", error_message)

        this.setState({ ...validator });
      }

    });
  };
  

  _onCheckTermCondition = () => {

    let fields = cloneDeep(this.state.fields);
        
    fields["term_contition"].value = !fields["term_contition"].value;

    this.setState({ fields });
  };


  /* ===== Images ===== */
  _uploadImageSelection = () => {

    this.setState({screen: "selection"})

  }

  _onDismissSelection = () => {

    this.setState({screen: ""})

  }

  _onSelectPhotos = () => {
    this.uploadIC.click()
  }

  _onSelectCamera = () => {

    this.captureIC.click()
  }
  
   _onSelectFile = (event, key) => {

    const file = event.target.files;
    if(file[0])
      this._convertToBase64(file[0], key);
      
  };

  _convertToBase64 = (file, key) => {
    let fields = cloneDeep(this.state.fields);

    fields[key].value = {file};

    this.setState({ fields, valueUpdated: true }, () => {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        
          let fields = cloneDeep(this.state.fields);          
          fields["identity_card_url"].value = reader.result;

          this.setState({ fields, screen: "" });
        
      });
      reader.readAsDataURL(file);
    });
  };
  

  /* ===== File ===== */
  _handleFileChange = (e,name) => {

    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    const file = e.target.files;

    if(file && file[0])
    {
      fields[name].value = {file: file[0], originalname: file[0].name}
  
      this.setState({ fields }, () => {
        
        let validator = validateField(fields, name, error_message)
  
        this.setState({ ...validator });
      });
    }
  };

  _removeFile = (name) => {

    let fields = cloneDeep(this.state.fields);
    fields[name].value = null

    this.setState({ fields });
  };

  _hideModalPDFView = () => {
    this.setState({ modalPDFViewVisible: false,  viewFileAction: "",  viewFilePath: ""});
  };


  _showModalPDFeView = (action) => {
    let fields = cloneDeep(this.state.fields);
    
    let doc = (fields[action].value) ? fields[action].value.file : null
    
    if(doc)
      this.setState({ modalPDFViewVisible: true, viewFileAction: action,  viewFilePath: doc });    
  };
  /* ===== END File ===== */


  _onSubmit = async () => {
    
    const { page }  = this.state;
    const { proceedRegisterParams } = this.props;

    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    let validator = await validateForm(fields, error_message);


    this.setState({ ...validator.data }, () => {
      if (!validator.valid) {
        this._handleInvalidForm();

      }else if(page === "org_detail"){

        this.setState({ page: "user_detail"}, () => {
          
          this._scrollToTop("container-top")
        });

      }else if(!fields["term_contition"].value)

        this.setState({screen: "term_policy_warning"})

      else{
        console.log(proceedRegisterParams)
        let params = {
          contact_no: proceedRegisterParams.contact_no,
          veri_code: proceedRegisterParams.veri_code
        };

        Object.keys(fields).forEach(key => {

          switch (key)
          {
            // case 'police_license':
            // case 'local_authority_license':
            // case 'ssm':
            // case 'identity_card':
                
            //   params.append(key, fields[key].value.file);
              

            // break;

            case 'organization_phoneNo':

              let countryCode = fields.organization_countryCode.value;

              params.organization_contact = countryCode + fields.organization_phoneNo.value;
              
            break;

            case 'recycling_category':
            case 'dumpsters_service':
            case 'structured_formatting':
              

              // [{"category_id": 1, "category_name": "Paper"},{"category_id": 2, "category_name": "Mix Bulk"}]
              params[key] = JSON.stringify(fields[key].value);
              
            break;
              
            default:
              params[key] = fields[key].value;

          }
        });
        
        this.setState({btnSubmited: true}, () => {
          
          this.props.onSignUp(params);
        })
      }
    });
  };

  _proceedContinue = () => {
    
    this.setState({screen: ""})
  }

  /* Location */
  _onMapLocation = () => {
    
    this._getCurrentLocation()    
  }

  _getCurrentLocation = () => {

    const success = position => {

      console.log("Location :", position);
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);

      this.setState({screen: "", page: "location"} , () => {

        this._getLocationFromLatLng({latitude: position.coords.latitude, longitude: position.coords.longitude}, true)
      })
          
    };

    const error = () => {
      this.setState({screen: "geolocation_failed"})
      console.log("Unable to retrieve your location");
    };

    if (navigator.geolocation) {

      console.log("GeoLocation Available");       
      navigator.geolocation.getCurrentPosition(success, error)
    } else {
      this.setState({screen: "geolocation_failed"})
      console.log("GeoLocation Not Available");
    }
  }

  _onCancelMapLocation = () => {
    
    this.setState({ page: "org_detail"});
  }

  _updateCoordinate = coordinates => {
    
    this._getLocationFromLatLng({latitude: coordinates.lat, longitude: coordinates.lng})
  };

  _getLocationFromLatLng  = (coordinates, current = false) => {

    Geocode.fromLatLng(coordinates.latitude, coordinates.longitude).then(response => {

      if(response.results && response.results[0] && response.results[0].formatted_address)
      {
        this._onUpdateLocation(coordinates, response.results[0].formatted_address, {secondary_text: response.results[0].formatted_address}, current)

        if(current)
          this._onUpdateCurrentLocation(coordinates, response.results[0].formatted_address)

      }
    },
    error => {
      console.error(error);
    })
  }

  _onUpdateLocation = (location, address, structured_formatting, current = false) => {
    
    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);

    fields.latitude.value = location.latitude
    fields.longitude.value = location.longitude
    fields.location_name.value = address
    fields.structured_formatting.value = structured_formatting
    
    this.setState({ fields }, () => {

      if(!current)
      {
        let validator = validateField(fields, "location_name", error_message)
  
        this.setState({ ...validator });

      }
    });
  }

  _onUpdateCurrentLocation = (location, address) => {
        
    this.setState({ currentLocation: {latitude: location.latitude, longitude: location.longitude, location_name: address} });
  }

  _onConfirmUpdateLocation = () => {

    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    
    this.setState({ locationConfirmed: true, page: "org_detail"}, () => {
      
      this._scrollToTop("coordinate-top")

      let validator = validateField(fields, "location_name", error_message)
  
      this.setState({ ...validator });
    })
  };
  /* Location */

  /* ===== Search =====*/
  _onSearchLocation = () => {

    this.setState({ searchLocation: true})
  }
 

  _onResetSearch = () => {

    this.setState({ search: ""})
  }

  _onCancelSearchLocation = () => {

    this.setState({ search: "", searchLocation: false})
  }

  selectAddress = (value) => {

    let fields = cloneDeep(this.state.fields);
    let address = String(value.address);
    let structureFormatting = value.structured_formatting;

    if (address !== "") {
      // FIXME: Address which have special characters will return wrong lat and lng. Example : Amigo Steak & Grill

      Geocode.fromAddress(address).then(
        response => {
                    
          const { lat, lng } = response.results[0].geometry.location;

          fields.latitude.value = lat
          fields.longitude.value = lng
          fields.location_name.value = address
          fields.structured_formatting.value = structureFormatting

          this.setState({ fields, searchLocation: false})

        },
        error => {
          console.error(error);
        }
      );
    }
  }

  _onUseCurrentLocation = () => {
    
    let fields = cloneDeep(this.state.fields);
    let { currentLocation } = this.state;

    fields.latitude.value = currentLocation.latitude
    fields.longitude.value = currentLocation.longitude
    fields.location_name.value = currentLocation.location_name
    fields.structured_formatting.value =  {secondary_text: currentLocation.location_name}

    this.setState({ fields, searchLocation: false})
  }


  _onChangeSearch = event => {
    
    this.setState(
      {
        search: event.target.value
      },
      () => {
        
        if (this.state.search) this.searchPlaces(this.state.search);
        
      }
    );
  };

  
  searchPlaces = text => {
    this.props.loadGooglePlaceAutoComplete(text);
  };

  /* ===== END Search =====*/

  _onChangeText = (name, value) => {
    let fields = cloneDeep(this.state.fields);

    if(["ic_no", "organization_phoneNo"].includes(name))
    {
      if(["organization_phoneNo"].includes(name) && value.length <= 10)
        fields[name].value = value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');  
      
      if(name === "ic_no" && value.length <= 12)
        fields[name].value = value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');  

    }else{

      fields[name].value = value;
    }

    this.setState({ fields });
  };

  _onChangeState = (value = "") => {

    let fields = cloneDeep(this.state.fields);
    fields["state"].value = value;
    
    this.setState({ fields }, () => {

      if(value !== "")
        this.props.onLoadRecyclingList({state: value});
    });
  };


  _onHandleBlurr = e => {
    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    let name = "";

    if (typeof e === "string") {
      name = e;
    } else {
      name = e.target.name;
    }

    let validator = validateField(fields, name, error_message);

    this.setState({ ...validator });
  };

  _handleInvalidForm = () => {
    let fields = cloneDeep(this.state.fields);
    Object.keys(fields).some(name => {
      if (!fields[name].valid) {
        return true;
      }
      return false;
    });
  };

  
  renderListLoading = (type) => {

    return (
      <div className="list-loading">

        {type === "grid" && (
          <React.Fragment>                
            <div className="main-item">          
                <div className="sub-rect pure-background"></div>
                <div className="sub-rect pure-background"></div>          
            </div>

            <div className="main-item">
                <div className="small-text">
                  <div className="small-sub-rect pure-background"></div>
                </div>
                
                <div className="small-text">
                  <div className="small-sub-rect pure-background"></div>
                </div>
            </div>

            <div className="main-item mt-3">          
                <div className="sub-rect pure-background"></div>
                <div className="sub-rect pure-background"></div>          
            </div>

            <div className="main-item">          
                <div className="small-text">
                  <div className="small-sub-rect pure-background"></div>
                </div>
                <div className="small-text">
                  <div className="small-sub-rect pure-background"></div>
                </div>
            </div>

            <div className="main-item mt-3">          
                <div className="sub-rect pure-background"></div>
                <div className="sub-rect pure-background"></div>          
            </div>
          </React.Fragment>
        )}
        
        {type === "list" && (
          <React.Fragment>                
            
            <div className="list-rect pure-background"></div>  
            <div className="list-rect pure-background mt-3"></div>  
            <div className="list-rect pure-background mt-3"></div>  
            <div className="list-rect pure-background mt-3"></div>
            <div className="list-rect pure-background mt-3"></div>
          </React.Fragment>
        )}
        
        {type === "summary" && (
          <React.Fragment>                
            
            <div className="list-rect pure-background"></div>  
            <div className="list-rect pure-background mt-3"></div>    
            <div className="css-dom mt-5"></div>
            <div className="css-dom mt-4"></div>
          </React.Fragment>
        )}
      </div>
    )
  }

  renderAutocompleteList = () => {

    const { search } = this.state;
    const { autocompleteList } = this.props;

    if(search !== "" && JSON.stringify(autocompleteList.data) !== JSON.stringify([]))
    {
      return(<div className="selection-container pt-4">
  
          {autocompleteList.data.map((location, index) => (
              
            <div 
              className={`recycling-container fix-height d-flex mb-4`} 
              key={index}
              onClick={() => this.selectAddress({ address: location.description, structured_formatting: location.structured_formatting }) }
            >                  
              <div className="desc-container full-width">
                <div className="recycling-img align-middle">
                  <i className="ion-ios-location icon color-primary font25" />
                </div>
                <div className="align-middle">
                  <div className="recycling-desc pl-3">

                    <div className="font-bold">
                      {location.structured_formatting ? location.structured_formatting.main_text : location.description}
                    </div>

                    {location.structured_formatting && location.structured_formatting.secondary_text && (

                      <div className="color-grey">
                        {location.structured_formatting ? location.structured_formatting.secondary_text : " "}
                      </div>
                    )}
                  </div>
                </div>
              </div>

            </div>
          ))}
        </div>
      )

    }else if(search !== "" && autocompleteList.isLoading === true)
    { 
      return (<div className="inner-container my-2 ml-2">
        {this.renderListLoading("list")}
      </div>)

    }else{
      return (<div className="inner-container text-center mt-5">
        <div>
          <img alt="" src={noResult}/>
        </div>
        <div className="color-grey mt-3">No result found</div>
      </div>)
    }
  };


  
  /* ===== Recycling Category Selection  =====*/  
  
  _proceedSelectCategory = () => {
    
    this.setState({page: "category_selection"}, () => {
      
      this._scrollToTop("container-top")
    })
    
  };

  _onCompleteCategorySelection = () => {

    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);

    this.setState({ page: "user_detail" }, () =>{

      this._scrollToTop("service-top")

      let validator = validateField(fields, "recycling_category", error_message)

      this.setState({ ...validator });

    });
  };

  
  _onSelectCategory = async (e, cat) => {

    let fields = cloneDeep(this.state.fields);

    if(fields["recycling_category_ids"].value.includes(cat.category_id))
    {
      fields["recycling_category_ids"].value.map((id,i) => {
  
        if(cat.category_id === id)
        {
          fields["recycling_category"].value.splice(i, 1)
          fields["recycling_category_ids"].value.splice(i, 1)
  
        }
        return true;
      })
    }else{
           
      fields["recycling_category_ids"].value.push(cat.category_id)

      fields["recycling_category"].value.push({          
        category_id: cat.category_id, 
        category_name: cat.category_name
      })
    }

    this.setState({ fields });
  };
  
  
  renderCategorySelection = () => {

    const { recyclingList, isLoadingRecyclingList } = this.props
    const { fields } = this.state

    if(isLoadingRecyclingList)
    {
      return (<div className="inner-container my-2 ml-2">
        {this.renderListLoading("list")}
      </div>)

    }else{
    
      return(<div className="selection-container">

          <div className="color-grey mb-4">Please select category</div>
  
          {recyclingList.map((row,i) => (
            <div 
              className={`recycling-container d-flex mb-4 ${(fields["recycling_category_ids"].value.includes(row.category_id)) ? "selected" : ""}`} 
              key={i}
              onClick={e => this._onSelectCategory(e, row) }
            >    
              
              <div className="desc-container">
                <div className="recycling-img">
                  <div className="isoImgWrapper">
                    <img alt="" src={(row.category_image) ? row.category_image : logo} />
                  </div>
                </div>
                <div className="recycling-desc pl-3 align-middle">
                  <div>{row.category_name}</div>
                </div>
              </div>
              <div className="recycling-checkbox left-col align-middle"> 
                <div className={`checkbox-outline ${fields["recycling_category_ids"].value.includes(row.category_id) ? "checked" : ""}`}>
                  {fields["recycling_category_ids"].value.includes(row.category_id) && (
                    <Icon
                      type="custom"
                      name="tick"
                      color={Color.White}
                      size={11}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }
  /* ===== END Recycling Category Selection  =====*/ 



  
  /* ===== Dumpsters Service Selection  =====*/  
  
  _proceedSelectService = () => {
    
    this.setState({page: "service_selection"}, () => {
      
      this._scrollToTop("container-top")
    })
    
  };

  _onCompleteServiceSelection = () => {

    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);

    this.setState({ page: "user_detail" }, () =>{

      this._scrollToTop("service-top")

      let validator = validateField(fields, "dumpsters_service", error_message)

      this.setState({ ...validator });

    });
  };

  
  _onSelectService = async (e, cat) => {

    let fields = cloneDeep(this.state.fields);

    if(fields["dumpsters_service_ids"].value.includes(cat.id))
    {
      fields["dumpsters_service_ids"].value.map((id,i) => {
  
        if(cat.id === id)
        {
          fields["dumpsters_service"].value.splice(i, 1)
          fields["dumpsters_service_ids"].value.splice(i, 1)
  
        }
        return true;
      })
    }else{
           
      fields["dumpsters_service_ids"].value.push(cat.id)

      fields["dumpsters_service"].value.push({          
        service_id: cat.id, 
        service_name: cat.service_name
      })
    }

    this.setState({ fields });
  };
  
  
  renderServiceSelection = () => {

    const { dumpsterServiceList, isLoadingDumpsterServiceList } = this.props
    const { fields } = this.state

    if(isLoadingDumpsterServiceList)
    {
      return (<div className="inner-container my-2 ml-2">
        {this.renderListLoading("list")}
      </div>)

    }else{
    
      return(<div className="selection-container">

          <div className="color-grey mb-4">Please select service</div>
  
          {dumpsterServiceList.map((row,i) => (
            <div 
              className={`recycling-container d-flex mb-4 ${(fields["dumpsters_service_ids"].value.includes(row.id)) ? "selected" : ""}`} 
              key={i}
              onClick={e => this._onSelectService(e, row) }
            >    
              
              <div className="desc-container">
                <div className="recycling-desc align-middle">
                  <div>{row.service_name}</div>
                </div>
              </div>
              <div className="recycling-checkbox left-col align-middle"> 
                <div className={`checkbox-outline ${fields["dumpsters_service_ids"].value.includes(row.id) ? "checked" : ""}`}>
                  {fields["dumpsters_service_ids"].value.includes(row.id) && (
                    <Icon
                      type="custom"
                      name="tick"
                      color={Color.White}
                      size={11}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }
  /* ===== END Category Selection  =====*/ 


  render() {
    
    const { stateDistrictList, view }  = this.props
    const { fields, page, locationConfirmed, searchLocation, btnSubmited, screen }  = this.state

    const countryCode = COUNTRY_CODE.map((codes, key) => (
      <option key={codes.dial_code}>{codes.dial_code}</option>
    ));

    let createMapOptions = {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    }
    

    return (
      <CustomStyles>
        
        <div className="pg-header">

          {["org_detail", "user_detail"].includes(page) && (
            
            <div className="text-bold text-center">Sign Up</div>
          )}

          {page === "location" && (
            
            <div className="text-bold text-center">Address</div>
          )}

          {page === "category_selection" && (
            
            <div className="text-bold text-center">Recycling Category</div>
          )}

          {page === "service_selection" && (
            
            <div className="text-bold text-center">Dumpster Service</div>
          )}
          
          <div 
            className="backBtn"
            onClick={() => this._proceedBack()}
          >
            <Icon
              type="custom"
              name="back"
              color={Color.Black}
              size={15}
            />
          </div>
        </div>

        {page === "location" ? (

          <MapStyle>
            
            {searchLocation ? (
              <React.Fragment>

                <div className="fixedTopButton input-container input-search">
                  <div className="input-box">
                    <i className="ion-ios-location icon red font25" />
                    <Input
                      size="large"
                      type="text"
                      name="search2" 
                      className="input-field search-location"                
                      placeholder="Search Location"
                      autoComplete="off"
                      value={this.state.search}
                      onChange={this._onChangeSearch}
                    />
                    {this.state.search !== "" && (
                      <span data-clear-input onClick={() => this._onResetSearch()}>&times;</span>
                    )}
                  </div>
                </div>


                <div className="location_container">
                  
                  <div className="current_container d-flex justify-content-between pt-3 pb-4">           
                    <div className="mt-2">
                      <span className="pl-2 font-bold">Use Current Location</span>
                    </div>        
                    
                    <div 
                      className="location-btn"
                      onClick={() => this._onUseCurrentLocation()}
                    >      
                      <Icon type="material" name="my-location" size={22} className="text-bold"/>
                    </div>                    
                  </div>

                  <div className="location-list">
                    {this.renderAutocompleteList()}
                  </div>
                </div>


              </React.Fragment>
            ) :(

              <React.Fragment>

                <div className="map-container">
                  <GoogleMap              
                    defaultOptions={createMapOptions}  
                    markerDraggable={true}              
                    coordinates={{lat: fields.latitude.value, lng: fields.longitude.value}}
                    onMarkerDragEnd={this._updateCoordinate}
                  />
                </div>

                <div 
                  className="fixedTopButton input-container"
                  onClick={() => this._onSearchLocation()}
                >
                  <i className="ion-ios-location icon red font25" />
                  <Input                  
                    size="large"
                    type="text"
                    name="search" 
                    autoComplete="off"
                    className="input-field"                   
                    value={fields["location_name"].value}
                  />
                </div>

              </React.Fragment>
            )}

          </MapStyle>
        ): (
            
          <SignUpStyleWrapper className="isoSignInPage"  id="container-top">
            
            <div className="isoLoginContentWrapper">
              <div className="isoLoginContent">

                <React.Fragment>

                  <div className="isoSignInForm">
                    <Form layout="vertical">

                      {page === "org_detail" && (    

                        <React.Fragment>

                          <div className="img-org">
                            <div className="isoImgWrapper">
                              <img alt=""  src={defaultOrganization}/>
                            </div>
                          </div>
              
                          {/* Organization Name */}
                          <div className="font-11 color-darkgrey">Organization Name</div>
                          <FormItem
                            validateStatus={
                              !fields["organization_name"].valid ? "error" : "validating"
                            }
                            help={this.state.error_message["organization_name"]}
                          >
                            <div className="pt-1">
                              <Input
                                size="large"
                                type="text"
                                name="organization_name"
                                placeholder="Enter Organization Name..."  
                                autoComplete="off"
                                value={fields["organization_name"].value}
                                onChange={event => this._onChangeText("organization_name", event.target.value)}
                                onBlur={this._onHandleBlurr}
                              />
                            </div>
                          </FormItem>
                          {/* END Organization Name */}
              
                          {/* Registration No */}
                          <div className="font-11 color-darkgrey" id="coordinate-top">Registration No.</div>
                          <FormItem
                            validateStatus={
                              !fields["registration_no"].valid ? "error" : "validating"
                            }
                            help={this.state.error_message["registration_no"]}
                          >
                            <div className="pt-1">
                              <Input
                                size="large"
                                type="text"
                                name="registration_no"
                                placeholder="Enter Registration No..."  
                                autoComplete="off"
                                value={fields["registration_no"].value}
                                onChange={event => this._onChangeText("registration_no", event.target.value)}
                                onBlur={this._onHandleBlurr}
                              />
                            </div>
                          </FormItem>
                          {/* END Registration No */}
                          

                          {/* Organization Contact */}
                          <div className="d-flex font-11 color-darkgrey">                  
                            <div className="width30">Country Code</div>
                            <div className="width68 pl-2">Phone No.</div>
                          </div>

                          <div className="isoInputWrapper">
                            <InputGroup compact>
                              <FormItem 
                                className="width30 pr-2"
                              >
                                <div className="pt-1">
                                  <Select
                                    size="large"
                                    disabled={true}
                                    value={fields["organization_countryCode"].value}
                                  >
                                    {countryCode}
                                  </Select>
                                </div>
                              </FormItem>

                              <FormItem 
                                validateStatus={
                                  !fields["organization_phoneNo"].valid ? "error" : "validating"
                                }
                                help={this.state.error_message["organization_phoneNo"]}
                                className="width68 pl-2"
                              >
                                <div className="pt-1">
                                  <Input
                                    size="large"
                                    type="text"
                                    className="full-width"
                                    // maxLength="10"
                                    pattern="[0-9]+" 
                                    placeholder="Enter Phone No..."  
                                    autoComplete="off"
                                    value={fields["organization_phoneNo"].value}
                                    onChange={event => this._onChangeText("organization_phoneNo", event.target.value)}
                                    name="organization_phoneNo"
                                    onBlur={this._onHandleBlurr}
                                  />
                                </div>
                              </FormItem>
                            </InputGroup>
                          </div>
                          {/* END Organization Contact */} 
              
                          {/* Organization State */}
                          <div className="font-11 color-darkgrey">State</div>
                          <FormItem
                            validateStatus={
                              !fields["state"].valid ? "error" : "validating"
                            }
                            help={this.state.error_message["state"]}
                          >
                            <div className="pt-1">                              
                              <Select
                                size="large"
                                value={fields["state"].value}
                                onChange={e => this._onChangeState(e)}
                                onBlur={e => this._onHandleBlurr("state", e)} 
                              >
                                <Option value="">- Select State -</Option>
                                {stateDistrictList.map((data, index) => {
                                  return (
                                    <Option value={data.state_name} key={index}>
                                      {data.state_name}
                                    </Option>
                                  );
                                })}
                              </Select> 
                            </div>
                          </FormItem>
                          {/* END Organization State */} 
                                                          
                          

                          {/* Coordinate */}
                          <div className="d-flex font-11 color-darkgrey">                  
                            <div className="width40">Latitude</div>
                            <div className="width40 pl-2">Longitude</div>
                          </div>

                          <div className="isoInputWrapper">
                            <InputGroup compact>
                              <FormItem className="width40 pr-2">
                                <div className="pt-1">
                                  <Input
                                    size="large"
                                    type="text"
                                    className="full-width"
                                    disabled={true}    
                                    placeholder="Latitude"
                                    autoComplete="off"
                                    value={(locationConfirmed) ? fields["latitude"].value : ""}
                                    name="latitude"
                                  />
                                </div>
                              </FormItem>

                              <FormItem className="width40 pl-2">
                                <div className="pt-1">
                                  <Input
                                    size="large"
                                    type="text"
                                    className="full-width"
                                    disabled={true}
                                    placeholder="Longitude"
                                    autoComplete="off"
                                    value={(locationConfirmed) ? fields["longitude"].value : ""}
                                    name="longitude"
                                  />
                                </div>
                              </FormItem>

                              <FormItem className="width18 pt-2" >
                                
                                <div onClick={this._onMapLocation}>
                                  <Icon
                                    type="material"
                                    name="my-location"
                                    size={25}
                                  />
                                </div>
                              </FormItem>
                            </InputGroup>
                          </div>
                          {/* END Coordinate */} 


              
                          {/* Organization Address */}
                          <div className="font-11 color-darkgrey">Address</div>
                          <FormItem
                            validateStatus={
                              !fields["location_name"].valid ? "error" : "validating"
                            }
                            help={this.state.error_message["location_name"]}
                          >
                            <div className="pt-1">
                              <Input
                                size="large"
                                type="text"
                                name="location_name"
                                placeholder="Address..."  
                                autoComplete="off"
                                value={(locationConfirmed) ? fields["location_name"].value : ""}
                                disabled={true}
                              />
                            </div>
                          </FormItem>
                          {/* END Organization Address */} 

                        </React.Fragment>
                      )}
    
                      
                      {page === "user_detail" && (   
                        <React.Fragment>                                  
                          
                      
                          <h4 className="mb-3">
                            <div className="font-bold">Main Grader</div>                           
                          </h4>
              
                          {/* Full Name */}
                          <div className="font-11 color-darkgrey" id="full_name">Full Name</div>
                          <FormItem
                            validateStatus={
                              !fields["full_name"].valid ? "error" : "validating"
                            }
                            help={this.state.error_message["full_name"]}
                          >
                            <div className="pt-1">
                              <Input
                                size="large"
                                type="text"
                                name="full_name"
                                placeholder="Enter Full Name..."  
                                value={fields["full_name"].value}
                                autoComplete="off"
                                onChange={event => this._onChangeText("full_name", event.target.value)}
                                onBlur={this._onHandleBlurr}
                              />
                            </div>
                          </FormItem>
                          {/* END Full Name */}                    
                          
              
                          {/* Grader ID */}
                          <div className="font-11 color-darkgrey" id="grader_id_name">Grader ID</div>
                          <FormItem
                            validateStatus={
                              !fields["grader_id_name"].valid ? "error" : "validating"
                            }
                            help={this.state.error_message["grader_id_name"]}
                          >
                            <div className="pt-1">
                              <Input
                                size="large"
                                type="text"
                                name="grader_id_name"
                                placeholder="Enter Grader ID..."  
                                value={fields["grader_id_name"].value}
                                autoComplete="off"
                                onChange={event => this._onChangeText("grader_id_name", event.target.value)}
                                onBlur={this._onHandleBlurr}
                              />
                            </div>
                          </FormItem>
                          {/* END Grader ID */}  


                          {/* Email */}
                          <div className="font-11 color-darkgrey" id="email">Email</div>
                          <FormItem
                            validateStatus={
                              !fields["email"].valid ? "error" : "validating"
                            }
                            help={this.state.error_message["email"]}
                          >
                            <div className="pt-1">
                              <Input
                                size="large"
                                type="text"
                                name="email"
                                placeholder="Enter Email..."  
                                value={fields["email"].value}
                                autoComplete="off"
                                onChange={event => this._onChangeText("email", event.target.value)}
                                onBlur={this._onHandleBlurr}
                              />
                            </div>
                          </FormItem>
                          {/* END IC No. */} 


                      
                          <h4 className="mb-3 mt-4 d-flex justify-content-between" id="service-top">
                            <div className="font-bold">Services</div>
                            <div className="color-grey"> Choose at least 1</div>                            
                          </h4>
                            
                          {/* Service Type */}
                          <FormItem
                            className="mb-0"
                            validateStatus={
                              !fields["dump_express"].valid ? "error" : "validating"
                            }
                            help={this.state.error_message["dump_express"]}
                          ></FormItem>

                          <FormItem className="mb-0" >
                            <div className="pt-1">

                              <div 
                                className="service-selection-box mb-3"
                                onClick={() => this._onChangeServiceType("dump_express")}
                              >
                                <div className="d-flex">
                                  <div className="isoImgWrapper">
                                    <img alt="" src={dumpExpress} />
                                  </div>
                                  <div className="pl-3 pt-2 font-bold">
                                    DumpExpress
                                  </div>
                                </div>
                                
                                <div className="recycling-checkbox pt-2"> 
                                  <div className={`checkbox-outline ${fields["dump_express"].value === true ? "checked" : ""}`}>
                                    {fields["dump_express"].value === true && (
                                      <Icon
                                        type="custom"
                                        name="tick"
                                        color={Color.White}
                                        size={11}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              
                              <div 
                                className="service-selection-box"
                                onClick={() => this._onChangeServiceType("dump_recycling")}
                              >
                                <div className="d-flex">
                                  <div className="isoImgWrapper">
                                    <img alt="" src={dumpRecycling} />
                                  </div>
                                  <div className="pl-3 pt-2 font-bold">
                                    DumpRecycling
                                  </div>
                                </div>
                                
                                <div className="recycling-checkbox pt-2"> 
                                  <div className={`checkbox-outline ${fields["dump_recycling"].value === true ? "checked" : ""}`}>
                                    {fields["dump_recycling"].value === true && (
                                      <Icon
                                        type="custom"
                                        name="tick"
                                        color={Color.White}
                                        size={11}
                                      />
                                    )}
                                  </div>
                                </div>                      
                              </div>

                              {/* Recycling Category */}                          
                              {fields["dump_recycling"].value && (
                                <div className="selected-category-container">
                                  <div 
                                    className="d-flex info-container justify-content-between"
                                    onClick={() => this._proceedSelectCategory()}
                                  >
                                    <div className="left-col pr-3">
                                      <div className="d-flex align-middle">
                                        <div className="pl-3"> 
                                          <div className="d-flex pb-1">
                                            <div className="isoLogoWrapper small round-background">
                                              <Icon
                                                type="custom"
                                                name="recycle"
                                                color={Color.Secondary}
                                              />
                                            </div>
                                            <div className="color-grey font-bold pl-2">Recycling Category</div>    
                                          </div>         
                                          {fields["recycling_category"].value.length > 0 ? (
                                            <div>
                                              {fields["recycling_category"].value.map((cat,i) => (
                                                <span key={i}>{(i > 0) ? ", ": ""}{cat.category_name}</span>
                                              ))}
                                            </div>
                                          ) : (
                                            <div>Select Recycling Category</div>
                                          )}
                                        </div>
                                      </div>
                                    </div> 
                                    <div className="align-middle next-icon">
                                      <Icon
                                        type="custom"
                                        name="next"
                                        color={Color.Secondary}
                                        size={12}
                                      />
                                    </div>
                                  </div>
                            
                                  <FormItem
                                    validateStatus={
                                      !fields["recycling_category"].valid ? "error" : "validating"
                                    }
                                    help={this.state.error_message["recycling_category"]}
                                  />      
                                </div>
                              )}
                              {/* END Recycling Category */}

                                  
                              <div 
                                className="service-selection-box mt-3"
                                onClick={() => this._onChangeServiceType("dump_waste")}
                              >
                                <div className="d-flex">
                                  <div className="isoImgWrapper">
                                    <img alt="" src={dumpWaste} />
                                  </div>
                                  <div className="pl-3 pt-2 font-bold">
                                    DumpWaste
                                  </div>
                                </div>
                                
                                <div className="recycling-checkbox pt-2"> 
                                  <div className={`checkbox-outline ${fields["dump_waste"].value === true ? "checked" : ""}`}>
                                    {fields["dump_waste"].value === true && (
                                      <Icon
                                        type="custom"
                                        name="tick"
                                        color={Color.White}
                                        size={11}
                                      />
                                    )}
                                  </div>
                                </div>                      
                              </div>

                              {/* Dumpster Service */}                          
                              {fields["dump_waste"].value && (
                                <div className="selected-category-container">
                                  <div 
                                    className="d-flex info-container justify-content-between"
                                    onClick={() => this._proceedSelectService()}
                                  >
                                    <div className="left-col pr-3">
                                      <div className="d-flex align-middle">           
                                        <div className="pl-3"> 
                                          <div className="d-flex pb-1">
                                            <div className="isoLogoWrapper small round-background">
                                              <Icon
                                                type="custom"
                                                name="dumpster"
                                                color={Color.Secondary}
                                              />
                                            </div>
                                            <div className="color-grey font-bold pl-2">Dumpster Service</div>    
                                          </div>        
                                          {fields["dumpsters_service"].value.length > 0 ? (
                                            <div>
                                              {fields["dumpsters_service"].value.map((cat,i) => (
                                                <span key={i}>{(i > 0) ? ", ": ""}{cat.service_name}</span>
                                              ))}
                                            </div>
                                          ) : (
                                            <div>Select Dumpster Service</div>
                                          )}
                                        </div>
                                      </div>
                                    </div> 
                                    <div className="align-middle next-icon">
                                      <Icon
                                        type="custom"
                                        name="next"
                                        color={Color.Secondary}
                                        size={12}
                                      />
                                    </div>
                                  </div>
                            
                                  <FormItem
                                    validateStatus={
                                      !fields["dumpsters_service"].valid ? "error" : "validating"
                                    }
                                    help={this.state.error_message["dumpsters_service"]}
                                  />      
                                </div>
                              )}
                              {/* END Dumpster Service */}

                            </div>
                          </FormItem>
                          {/* END Service Type */}

                                    
                          <FormItem>      
                            <div 
                              className="d-flex mt-4 mb-2"                              
                              onClick={() => this._onCheckTermCondition()}
                            >
                              
                            <div className="form-checkbox align-middle"> 
                              <div className={`checkbox-outline ${fields["term_contition"].value ? "checked" : ""}`}>
                                {fields["term_contition"].value && (
                                  <Icon
                                    type="custom"
                                    name="tick"
                                    color={Color.White}
                                    size={11}
                                  />
                                )}
                              </div>
                            </div>I have read and agreed to the Terms of Use and Privacy Policy.</div>
                          </FormItem> 
                        </React.Fragment>
                      )}

                      {page === "category_selection" && (
                        <React.Fragment>{this.renderCategorySelection()}</React.Fragment>
                      )}

                      {page === "service_selection" && (
                        <React.Fragment>{this.renderServiceSelection()}</React.Fragment>
                      )}

                    </Form>
                  </div>
                </React.Fragment>
              
              </div>
            </div>

            <ToastContainer autoClose={2000} />
          </SignUpStyleWrapper>
        )} 

        {(page === "org_detail" || page === "user_detail") && (

          <div className="buttonBottom">        
            <Button
              type="default"
              size="large"
              className="bottom-btn primary-btn"
              onClick={this._onSubmit}
              loading={btnSubmited}
            >
              {page === "org_detail" ? "Next" : "Sign Up"}
            </Button>
          </div>
        )}


        {(page === "category_selection") && (

          <div className="buttonBottom">        
            <Button
              type="default"
              size="large"
              className="bottom-btn primary-btn"
              onClick={this._onCompleteCategorySelection}
            >
              Confirm
            </Button>
          </div>
        )}


        {(page === "service_selection") && (

          <div className="buttonBottom">        
            <Button
              type="default"
              size="large"
              className="bottom-btn primary-btn"
              onClick={this._onCompleteServiceSelection}
            >
              Confirm
            </Button>
          </div>
        )}


        {(page === "location" && !searchLocation) && (
          <div className="buttonBottom d-flex">
            <div className="btn-1">
              <Button
                type="default"
                size="large"
                className="bottom-btn grey-btn mr-2"
                onClick={() => this._onCancelMapLocation()}

              >
                Cancel
              </Button>
            </div>
            <div className="btn-2">
              <Button
                type="default"
                size="large"
                className="bottom-btn primary-btn ml-2"
                onClick={() => this._onConfirmUpdateLocation()}
              >
                Confirm
              </Button>
            </div>
          </div>
        )}

        
        {screen !== "" && (
          <div className="ant-modal-root">
            <div className="ant-modal-mask"></div>
            <div className="ant-modal-wrap instagram-modal">
              <div className="ant-modal">
                <div className="ant-modal-content">
                  <div className="ant-modal-body">

                    {screen === "selection" && (
                      <div className="button-selection">

                        {view !== "DesktopView" && (
                          <Button
                            className="selection"
                            size="large"
                            type="default"
                            onClick={() => this._onSelectCamera()}
                          >
                            Camera
                          </Button>
                        )}
                        
                        <div className="mt-2">
                          <Button
                            className="selection"
                            size="large"
                            type="default"
                            onClick={() => this._onSelectPhotos()}
                          >
                            Photos
                          </Button>
                        </div>

                        <div className="mt-2">
                          <Button
                            className="bottom-btn grey-btn"
                            size="large"
                            type="default"
                            onClick={() => this._onDismissSelection()}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    )}

                    {screen === "term_policy_warning" && (
                      <div>
                        <div className="padding-10 mb-3">
                          <div className="text-bold font-18">Warning</div>
                          <div className="pt-3 pb-3">Please indicate that you have read and agree to the Terms and Privacy Policy.</div>
                        </div>

                        <div className="width100">                        

                          <Button
                            className="bottom-btn green-btn"
                            size="large"
                            type="default"
                            onClick={() => this._proceedContinue()}
                          >
                            Okay
                          </Button>
                        </div>

                      </div>
                    )}

                    {screen === "discard_change_confirm" && (
                      <div>
                        <div className="pending-order-container mb-3">
                          <div className="text-bold font-18">Dismiss Sign Up? </div>
                        </div>

                        <div className="d-flex justify-content-between width100">                        

                          <Button
                            className="bottom-btn grey-btn mr-2"
                            size="large"
                            type="default"
                            onClick={() => this._proceedBack(true)}
                          >
                            Discard
                          </Button>

                          <Button
                            className="bottom-btn green-btn ml-2"
                            size="large"
                            type="default"
                            onClick={() => this._onContinue()}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    )}

                    {screen === "geolocation_failed" && (
                      
                      <div>
                        <div className="pending-order-container mb-3">
                          <div className="text-bold font-18">Location Access Failed!</div>
                          <div className="pt-3">We are unable to retrieve your location. Please enable your location access and click <span className="text-bold color-primary">Retry</span> to use this features.</div>
                        </div>  
                        <div className="d-flex justify-content-between width100">                        

                          <Button
                            className="bottom-btn green-btn ml-2"
                            size="large"
                            type="default"
                            onClick={() => this._getCurrentLocation()}
                          >
                            Retry
                          </Button>
                        </div>                        
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal Warning */}
        <ModalWarning
          view={this.props.view}
          visible={this.state.modalWarningVisible}
          onDismiss={this._onHideWarningModal}
        />
        {/* END Modal Warning */}


        {/* Modal Invoice PDF */}
        <ModalPDFView
            view={this.props.view}
            visible={(this.state.modalPDFViewVisible && this.state.viewFileAction !== "" && this.state.viewFilePath) ? true : false}
            onDismiss={this._hideModalPDFView}
            onPrint={this._onPrint}
            data={{
              url:(this.state.viewFilePath && this.state.viewFileAction !== "") ? this.state.viewFilePath : ""
            }}
        />
      
        {/* END Modal Invoice PDF */}
      </CustomStyles>
    );
  }
}

const mapStateToProps = state => {
  const { profileData, proceedRegisterParams, isSignupSuccess, signup_verify_email, isSignupFailed } = state.Auth;
  const { stateDistrictList, recyclingList, isLoadingRecyclingList, dumpsterServiceList, isLoadingDumpsterServiceList } = state.General;
  const { autocompleteList } = state.GoogleMap; 
  const { field_errors, view} = state.App;

  return { profileData, proceedRegisterParams, stateDistrictList, recyclingList, isLoadingRecyclingList, autocompleteList, field_errors, view, isSignupSuccess, signup_verify_email, isSignupFailed, dumpsterServiceList, isLoadingDumpsterServiceList };

};

const mapDispatchToProps = dispatch => {
  return {
    
    onSignUp: params => dispatch(actions.signUp(params)),
    onDismissRegister: () => dispatch(actions.dismissRegistration()),
    onLoadRecyclingList: data => dispatch(actions.loadRecyclingList(data)),
    onLoadDumpsterService: data => dispatch(actions.loadDumpsterService(data)),
    onLoadStateDistrictList: () => dispatch(actions.loadStateDistrictList()),
    loadGooglePlaceAutoComplete: param => dispatch(actions.loadGooglePlaceAutocomplete(param)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(SignUp);
